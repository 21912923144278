.header {
  background: $white;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  + main {
    margin-top: 120px;
  }
  @include media-breakpoint-down(lg) {
    + main {
      margin-top: 90px;
    }
  }
  @include media-breakpoint-up(xl) {
    position: relative;
    + main {
      margin-top: 0;
    }
  }

  .header-top {
    padding: 6px 0;
    @include media-breakpoint-up(xl) {
      padding: 6px 0;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    a {
      display: block;
      img {
        height: 23px;
        @include media-breakpoint-up(xl) {
          height: 38px;
        }
      }
    }
  }

  .top-nav {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        padding: 0px 23px;
        border-left: 1px solid #dadee4;
      }
      + .item {
        margin-left: 24px;
        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }
      .icon {
        font-size: $font-size-18;
        color: $primary;
        position: relative;
        .badge {
          background: $error;
          font-size: $font-size-12;
          font-weight: 400;
          position: absolute;
          top: -3px;
          right: -19px;
          @include media-breakpoint-down(lg) {
            font-size: $font-size-10;
            padding: 2px 4px;
            top: 0;
            right: -4px;
          }
        }
      }
      .text {
        display: none;
        @include media-breakpoint-up(lg) {
          font-size: $font-size-14;
          line-height: 20px;
          text-transform: uppercase;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
      .dropdown-toggle {
        text-align: center;
        &::after {
          display: none;
        }
        .text {
          &::after {
            content: '\f078';
            font-family: $fontawesome;
            font-weight: 300;
            border: none;
            font-size: $font-size-10;
            line-height: 1;
            color: #939599;
            margin-left: 6px;
          }
        }
      }
    }
  }

  &.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $blue;
    color: $white;
    transition: all 0.3s ease-in-out;
    + main {
      margin-top: 156px;
    }

    .logo {
      filter: brightness(0) invert(1);
      margin-left: 40px;
    }

    .search-box {
      .icon {
        color: $white;
      }
      input {
        background: rgba($white, 0.2);
        border: none;
        color: $white;
      }
    }

    .top-nav {
      color: #c8c8c8;
      .item {
        border-left-color: #032c40;
      }
    }

    .header-menu {
      .btn-menu {
        position: absolute;
        width: 40px;
        height: 104px;
        top: -84px;
        left: 0;
        font-size: 0;
        padding: 0;
        align-items: center;
        background: none;
        .icon-menu {
          margin-left: 0;
        }
      }
      .site-navigation {
        display: none;
        .site-navigation-item {

        }
      }
    }
  }
}

.search-box {
  position: relative;
  padding: 6px 16px;
  @include media-breakpoint-up(xl) {
    padding: 0;
  }
  .icon {
    position: absolute;
    color: #939599;
    top: 15px;
    left: 28px;
    @include media-breakpoint-up(xl) {
      top: 8px;
      left: 14px;
    }
  }
  input {
    font-size: $font-size-14;
    line-height: 20px;
    border: 1px solid $lightgray;
    border-radius: 4px;
    width: 100%;
    padding: 9px 16px 9px 32px;
    @include media-breakpoint-up(lg) {
      width: 580px;
      padding: 10px 16px 10px 44px;
    }
    &::placeholder {
      color: #939599;
    }
  }

  

  .search-box-dismiss {
    background: $primary;
    pointer: cursor;
    color: #333;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 101;
    padding: 10px 0;
    display: none;
  }

  .search-box-results.active + .search-box-dismiss {
    &.mobile-only {
      display: block;
    }
  }


  .search-box-results {
    display: none;
    width: 100%;
    // min-width: 800px;
    // max-width: 800px;
    background: white;
    // -webkit-column-width: 170px;
    // -moz-column-width: 170px;
    // o-column-width: 170px;
    // -ms-column-width: 170px;
    // column-width: 170px;
    position: absolute;
    // max-height: 500px;
    overflow: auto;
    position: absolute;
    padding: 12px;
    z-index: 100;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    @media (max-width: 768px) {
      left: 0;
      height: calc(100vh - 140px);
    }
    &.active {
      display: block;
    }
    // & + .search-box-overlay {
    //   display: none;
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: rgba(0, 0, 0, 0.5);
    //   z-index: 500;
    // }
    // &.active + .search-box-overlay {
    //   display: block;
    // }
    .category-results {
      display: inline-block;
      width: 49%;
      vertical-align: top;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .item-results {
      display: inline-block;
      width: 49%;
      vertical-align: top;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .viewmore-link {
      color: #CC9999;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }
    .simple-search-row {

    }
    .complex-search-row {
      .product-name {
        -webkit-line-clamp: unset;
        min-height: auto;
      }
      .product-item {
        display: flex;
      }
      .product-item .product-caption .product-price {
        font-size: 0.875rem;
        .new {
          font-size: 0.875rem;
          margin-right: 2px;
        }
      }
      .product-item .product-img {
        width: 50px;
        height: auto;
        margin-right: 5px;
        margin-top: 2px;
      }
      .product-item .product-caption {
        flex: 1;
        .product-price {
          align-items: baseline !important;
        }
      }
    }
  }
}

.suusi-logo {
  max-width: 120px;
  max-height: 50px;
  @include media-breakpoint-down(lg) {
    max-height: 15px !important;
  }
}