input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-floating {
  > label {
    color: #939599;
    padding: 0.625rem 0.75rem;
  }
  > .form-control,
  > .form-select {
    &:focus {
      &::placeholder {
        color: #939599;
      }
    }
    &:focus,
    &:not(:placeholder-shown) {
      ~ label {
        background: $white;
        padding: 0 6px;
        height: auto;
        line-height: 1;
      }
    }
  }
  &.show-placeholder-on-pc {
    @include media-breakpoint-up(lg) {
      > .form-control,
      > .form-select {
        &::placeholder {
          color: #939599;
        }
      }
    }
  }
}

.form-label {
  font-size: $font-size-12;
  color: #939599;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-14;
  }
  &.bold-on-mobile {
    @include media-breakpoint-down(lg) {
      font-size: $font-size-14;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
}

.hide-on-mobile {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.hide-on-pc {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.form-control {
  font-size: $font-size-16;
  border-radius: 4px;
  padding: 0.6875rem 1rem;
  color: $main;
  &::placeholder {
    opacity: 0.5;
  }
}

.form-check-label {
  font-size: $font-size-14;
  line-height: 24px;
  color: $black;
}

.form-text {
  color: #515151;
  font-size: $font-size-12;
  line-height: 1.2rem;
  @include media-breakpoint-up(md) {
    font-size: $font-size-14;
  }
  * {
    color: #515151;
  }
}

.form-button {
  margin-top: 40px;
}

.form-check-input[type='checkbox'] {
  border-radius: 0;
}
.form-check {
  &.lg {
    .form-check-input[type='checkbox'] {
      width: 20px;
      height: 20px;
      + label {
        margin-left: 8px;
      }
      &:checked {
      }
    }
  }
}

.input-amount {
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid #dadee4;
  min-width: 160px;
  .btn-adjust {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    font-size: $font-size-14;
    flex: 1 0 auto;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  input {
    flex: auto;
    padding: 7px;
    text-align: center;
    border: none;
    border-right: 1px solid #dadee4;
    min-width: 0;
    background: none;
  }
  .btn-adjust{
    + input {
      border-left: 1px solid #dadee4;
    }
  }

  &.md {
    font-size: $font-size-12;
    min-width: 80px;
    .btn-adjust {
      width: 24px;
    }
    input {
      padding: 3px;
    }
  }
}

// FORM

.radio-custom {
  padding-left: 0;
  position: relative;
  + .radio-custom {
    margin-top: 12px;
  }

  > .form-check-input[type='radio'] {
    position: absolute;
    top: 14px;
    left: 40px;
    z-index: 1;
  }

  > label {
    display: flex;
    position: relative;
    border: 1px solid #b2bcca;
    border-radius: 4px;
    padding: 16px 16px 16px 40px;
  }

  > .form-check-input:checked[type='radio'] {
    + label {
      background: #fcf0ea;
      border-color: $primary;

      // form
      .invoice-form {
        .form-floating {
          > input {
            background: none;
            &:not(:placeholder-shown) {
              ~ label {
                background: $secondary;
              }
            }
            &:focus {
              ~ label {
                color: $primary;
                background: $secondary;
              }
            }
          }
          > label {
          }
        }
      }
    }
  }

  .value {
    font-weight: 600;
    line-height: 1.3;
    color: $black;
  }
  .desc {
    font-size: $font-size-14;
    line-height: 1.3;
    font-weight: 400;
    color: #4f4f4f;
    padding-left: 24px;
  }
  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.has-form {
    > label {
      display: block;
    }
  }
}
.invoice-form {
  margin-top: 20px;
  margin-left: -1.5rem;
  @include media-breakpoint-up(xl) {
    padding: 0px 14px;
  }
  .form-floating {
    > label {
      font-weight: normal;
    }
  }
}

.add-user-form {
  @include media-breakpoint-up(md) {
    padding: 0px 40px;
  }
  .btn-addmore {
    font-size: $font-size-14;
  }
  .form-row-more {
    display: flex;
    .form-group {
      flex: auto;
    }
    .btn-removerow {
      display: flex;
      height: 48px;
      align-items: center;
      margin-left: 16px;
      font-size: $font-size-18;
    }
  }
}



.posti-destinations {
  display: none;
  padding-left: 20px;
  .posti-destination {
    font-size: 0.875rem;
    line-height: 24px;
    color: #000;
    display: flex;
    position: relative;
    border: 1px solid #b2bcca;
    border-radius: 4px;
    padding: 16px 16px 16px 40px;
    .value {
      font-weight: normal;
    }
  }
}
#select-shipping-method input[name="method_code"]:checked {
  & ~ .posti-destinations {
    display: block;
  }
}