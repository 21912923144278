.ham-modal {
  &.auth-modal {
    --bs-modal-width: 440px;
  }
  .modal-dialog {
    &.modal-dialog-scrollable {
    }
    .modal-content {
      border: none;
      background: $white;
      box-shadow: 0px 6px 43px rgba(0, 0, 0, 0.21);
      border-radius: 0px;
      overflow: auto;
      padding: 32px 16px;
      @include media-breakpoint-up(md) {
        padding: 45px 60px;
      }
      .modal-header {
        padding: 0;
        border: none;
        background: none;
        margin-bottom: 10px;
        .modal-title {
        }
        .btn-close {
        }
      }
      .modal-body {
        padding: 0;
        overflow: visible;
        font-size: $font-size-14;
        // @include disabledScrollbar();
        @include media-breakpoint-up(md) {
          font-size: $font-size-16;
        }
      }
      .modal-footer {
        justify-content: flex-start;
        border: none;
        padding: 0;
        * {
          margin: 0;
        }
      }
    }
  }

  &.review-modal {
    @include media-breakpoint-up(md) {
      --bs-modal-width: 730px;
    }
    .modal-dialog {
      margin: 0;
      height: 100vh;
      @include media-breakpoint-up(md) {
        height: auto;
        margin: var(--bs-modal-margin) auto;
      }
      .modal-content {
        padding: 0;
        height: 100vh;
        @include media-breakpoint-up(md) {
          height: auto;
        }
        .modal-header {
          margin: 0;
          .btn-close {
            position: absolute;
            right: 30px;
          }
        }
        .modal-body {
          padding: 0px 30px;
          flex: unset;
          @include media-breakpoint-up(md) {
            flex: 1 1 auto;
          }
        }
        .modal-footer {
          margin: 0;
          padding: 24px 30px;
        }
      }

      .item-review {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 12px 30px;
        width: 100%;
        .item-img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          background: $white;
          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
        .item-caption {
          padding-left: 12px;
          .item-name {
            font-size: $font-size-12;
            font-weight: 700;
            margin-bottom: 0;
          }
          .item-sku {
            font-size: $font-size-12;
            color: #939599;
          }
        }
      }

      .input-rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 0;
        label {
          font-size: $font-size-18;
          font-weight: 700;
          margin-bottom: 8px;
        }
        ul {
          display: flex;
          justify-content: center;
          font-size: $font-size-24;
          color: #5f6368;
          li {
            + li {
              margin-left: 12px;
            }
          }
        }
      }
    }
    .btn-addreview {
      width: 250px;
    }
  }

  &.personal-modal {
    --bs-modal-width: 540px;
    .modal-dialog {
      margin: 0;
      height: 100vh;
      @include media-breakpoint-up(md) {
        height: auto;
        margin: var(--bs-modal-margin) auto;
      }
      .modal-content {
        height: 100vh;
        border-radius: 0;
        @include media-breakpoint-up(md) {
          height: auto;
          padding: 24px;
          border-radius: 4px;
        }
        .modal-header {
          justify-content: center;
          .modal-title {
            font-size: $font-size-24;
            font-weight: 700;
            padding-bottom: 16px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eaeaea;
            width: 100%;
            @include media-breakpoint-up(md) {
              width: auto;
              font-size: $font-size-18;
              padding-bottom: 0;
              margin: 0 0 30px;
              border-bottom: none;
            }
          }
          .btn-close {
            position: absolute;
            top: 42px;
            right: 16px;
            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
        .modal-body {
        }
        .modal-footer {
          justify-content: center;
          padding-bottom: 16px;
          .btn {
            width: 100%;
            @include media-breakpoint-up(md) {
              width: auto;
              min-width: 200px;
            }
          }
        }
      }
    }
  }

  &.status-modal {
    .modal-dialog {
      .modal-content {
        border-radius: 6px;
        .modal-header {
          flex-direction: column;
          align-items: flex-start;
          .status-icon {
            font-size: $font-size-28;
            line-height: 1;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 58px;
            height: 58px;
            border-radius: 100px;
            color: $white;
          }
          .modal-title {
            font-size: $font-size-28;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        .modal-body {
          margin-bottom: 40px;
          .status-message {
            font-size: $font-size-14;
          }
        }
        .modal-footer {
          flex-wrap: nowrap;
        }
      }
    }
  }
}
