.cart-page {
  .cart-header {
    font-size: $font-size-32;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.cart-table {
  width: 100%;
  margin-bottom: 12px;
  thead {
    display: none;
    @include media-breakpoint-up(md) {
      display: table-header-group;
    }
    th {
      font-size: 12px;
      padding: 11px 20px;
      font-weight: 400;
      text-transform: none;
      background: $white;
      border-bottom: 6px solid #f9f9f9;
      &:first-child {
        width: 280px;
      }
    }
  }
  tbody {
    background: $white;
    tr {
      @include media-breakpoint-down(sm) {
        display: block;
        border-top: 1px solid #eaeaea;
        padding: 24px 0;
        position: relative;
      }
    }
    td {
      display: block;
      background: $white;
      font-size: $font-size-12;
      @include media-breakpoint-up(md) {
        display: table-cell;
        padding: 15px 20px;
      }
      @include media-breakpoint-down(sm) {
        + td {
          margin-left: 70px;
        }
        &:nth-child(2) {
          margin-top: -30px;
          margin-bottom: 10px;
        }
        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .input-amount {
    width: 120px;
    align-items: center;
    min-width: 120px;
    @include media-breakpoint-up(md) {
      width: 60px;
    }
    .btn-link {
      font-size: $font-size-10;
      font-weight: 400;
      padding: 0rem 0.5rem;
    }
  }

  label {
    color: #939599;
    font-size: $font-size-12;
    padding-right: 16px;
  }

  .btn-remove {
    font-size: $font-size-18;
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 75px;
      right: 0;
    }
  }
  .btn-save {
    font-size: $font-size-18;
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 75px;
      right: 28px;
    }
  }
}

.cart-item {
  display: flex;
  .item-img {
    display: flex;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .item-caption {
    flex: 1;
    .item-name {
      font-size: $font-size-12;
      font-weight: 700;
      margin-bottom: 0;
      @include textOverflow(2);
    }
    .item-sku {
      color: #939599;
    }
  }
}

.billing-section {
  background: #f7f7f7;
  padding: 24px 16px;
  margin: 0px -16px;
  @include media-breakpoint-up(md) {
    margin: 0;
    padding: 26px 30px;
    background: $white;
  }
  .billing-title {
    font-size: $font-size-16;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .billing-value {
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    .row-value {
      min-height: 36px;
      label {
        margin-bottom: 0;
      }
    }
    table {
      background: none;
      tbody {
        background: none;
      }
      th,
      td {
        background: none;
        font-weight: 400;
        border: none;
        font-size: $font-size-16;
        min-height: 2rem;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .billing-total {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
  }
}
