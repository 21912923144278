.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-neutral {
  color: $neutral;
}

.text-error {
  color: $error;
}

.text-gray {
  color: $gray;
}

.text-lightgray {
  color: $lightgray;
}

.text-white {
  color: $white;
}
