$stepper-desktop-padding: 20px;
$stepper-mobile-padding: 10px;
$stepper-font-size: 0.875rem;
$stepper-color-done: #178A00;
$stepper-color-current: $primary;
$stepper-color-pending: #807370;
$stepper-color-hover: #000;
$stepper-divider-color: #E2DFDA;

$font-size-base: 15px !default;

// Breakpoints

$screen-xsm: 25rem;
$screen-sm: 45rem;
$screen-lg: 960px;

// Placeholder Styles

.stepper-wrapper {
  margin: 0 auto;
  // background: #fff;
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  // border-radius: 4px;
  
  @media (min-width: $screen-sm) {
    max-width: 960px;
  }
}

// Stepper

.stepper {
  font-size: $stepper-font-size;
  margin-bottom: 1.5rem;
  @media (min-width: $screen-sm) {
  }
  
  // List
  
  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
    }
    
    // Items
    &__item {
      padding: 3px 5px;
      text-align: center;
      position: relative;

      display: flex;
      align-items: center;
      
      @media (min-width: $screen-sm) {
        padding: 10px;
        flex-direction: column;
        flex: 1;
      }
      
      // background: #f6f6f6;
        
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;

        // Mobile only
        // border-right: 2px dotted $stepper-divider-color;
        // height: 20%;
        // left: 16px;
        // top: 31px;
        
        // Tablet and desktop
        @media (min-width: $screen-sm) {
          width: calc(100% - 100px);
          top: 28%;
          left: calc(50% + 50px);
          border-top: 2px dotted $stepper-divider-color;
          // border-right: none;
        }
      }
      
      // Done
      &--done {
        color: $stepper-color-done;
        transition: all .1s;
        
        &:after {
          // border-right-style: solid;
          // border-right-width: 1px;
          
          @media (min-width: $screen-sm) {
            border-top-style: solid;
            border-top-width: 1px;
            // border-right: none;
          }
        }
      
        &:hover,
        &:focus {
          // text-decoration: underline;
          // // color: $stepper-color-hover;
          // cursor: pointer;
        }
      }
      
      // Current
      &--current {
        color: $stepper-color-current;
        
        // FIX
        &:last-of-type,
        &:only-of-type {
          &:after {
            height: 30%;
          }
        }
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
      
      // Pending
      &--pending {
        color: $stepper-color-pending;
        
        &:after {
          height: 30%;
        }
      }
    }
      
    &__title {
      margin: 1px 0 0;
      
      @media (min-width: $screen-sm) {
        margin: 0;
      }
      &:hover{
        color: inherit;
      }
    }

    // Icons
    &__icon {
      margin: 0 10px 0 0;
      font-size: 1.5rem;
      min-width: 24px;
      text-align: center;

      @media (min-width: $screen-sm) {
        margin: 0 0 15px;
      }

      path {
        fill: currentColor;
      }
      ellipse,
      circle {
        stroke: currentColor;
      }
    }
  }
}