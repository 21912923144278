.btn {
  font-size: $font-size-14;
  font-weight: 600;
  line-height: 20px;
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-border-radius: 0;
  box-sizing: border-box;

  > i {
    font-size: $font-size-18;
    vertical-align: middle;
  }

  &.btn-sm {
    font-size: $font-size-14;
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 1rem;
  }

  &.btn-lg {
    font-size: $font-size-16;
    --bs-btn-padding-y: 0.8125rem;
  }

  &.rounded {
    border-radius: 4px !important;
  }

  &.btn-padding-x-lg {
    --bs-btn-padding-x: 2rem;
  }

  &:disabled {
    color: $white;
    background: #dadce0;
    border-color: #dadce0;
    opacity: 1;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn-light {
  --bs-btn-color: #cc9999;
  --bs-btn-bg: #fcf0ea;
  --bs-btn-border-color: #fcf0ea;
  --bs-btn-hover-color: #cc9999;
  --bs-btn-hover-bg: darken(#fcf0ea, 10%);
  --bs-btn-hover-border-color: darken(#fcf0ea, 10%);
  --bs-btn-focus-shadow-rgb: 223, 104, 42;
  --bs-btn-active-color: #cc9999;
  --bs-btn-active-bg: #fcf0ea;
  --bs-btn-active-border-color: #fcf0ea;
}

.btn-outline-primary {
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn-outline-secondary {
  --bs-btn-color: #333;
  --bs-btn-border-color: #bdbdbd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cc9999;
  --bs-btn-hover-border-color: #cc9999;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc9999;
  --bs-btn-active-border-color: #cc9999;
  --bs-btn-focus-shadow-rgb: 223, 104, 42;
}

.btn-link {
  --bs-btn-color: #000;
  text-decoration: none;
  i {
    margin-right: 16px;
  }
}

.btn-link-primary {
  --bs-btn-color: #cc9999;
}

.dropdown-select {
  font-size: 0.875rem;
  .dropdown-toggle {
    border-radius: 4px;
    border: 1px solid #dadee4;
    color: #828282;
    padding-right: 13px;
    padding-left: 0px;
    // min-width: 350px;
    font-weight: 400;
    text-align: left;
    position: relative;
    // padding-left: 0;
    // padding-right: 0;
    &::after {
      position: absolute;
      top: 16px;
      right: 5px;
    }
  }
  strong {
    font-weight: 600;
    color: #333;
  }
  table {
    // width: 100%;
    tr {
      padding-left: 0;
      padding-right: 0;
    }
    td {
      padding: 0 .5rem;
    }
  }
  .dropdown-menu {
    border-radius: 4px;
    font-size: 0.875rem;
    // min-width: 350px;
    max-height: 200px;
    overflow: auto;
    // display: block;
    margin-top: .3rem;    
  }
  .dropdown-item td {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: lightgrey;
      position: absolute;
      top: 0.6rem;
      right: 0;
      border: 0;
    }
    &:last-child:after {
      content: '';
      display: none;
    }
  }
  .dropdown-toggle {
    @media (max-width: 420px) {
      width: 100%;
      .dropdown-item {
        border-bottom: none;
      }
    }
    .dropdown-item td {
      &:after {
        height: 80%;
        top: 0.2rem;
      }
    }
  }
  .dropdown-menu, .dropdown-select {
    .dropdown-item td {
      padding: .5rem .5rem;
      position: relative;
    }
    @media (max-width: 420px) {
      width: 100%;
      table {
        width: 100%;
      }
      .dropdown-item {
        border-bottom: 1px solid lightgrey;
      }
    }
  }
  .dropdown-item {
    cursor: pointer;
    color: #828282;
    opacity: 0.8;
    display: table-row;
    @media (max-width: 420px) {
      display: grid;
      td:after {
        display: none;
      }
    }
    .col {
      flex: 1;
    }

    &:hover {
      // background: #fcf0ea;
      opacity: 0.7;
    }
    &.hidden {
      display: none;
    }
    &.active {
      color: #828282;
      background: #fcf0ea;
      strong {
        color: #333;
      }
    }
  }
}

.btn-suusi-banner-cta {
  --bs-btn-bg: #000;
  --bs-btn-color: #fff;
}