.section-home {
  display: block;
}

.home-category {
  margin-top: 20px;
  // @include media-breakpoint-up(xl) {
  //   margin-top: 20px;
  // }
  // .row {
  //   @include media-breakpoint-down(sm) {
  //     display: block;
  //     white-space: nowrap;
  //     overflow: auto;
  //     padding: 0px 12px;
  //     @include disabledScrollbar();
  //     .col {
  //       display: inline-block;
  //       width: 150px;
  //     }
  //   }
  // }
}

.btn-opencate {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dadee4;
  border-radius: 4px;
  padding: 4px 12px;
  position: relative;
  cursor: pointer;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
      filter: grayscale(1);
    }
  }
  .text {
    font-size: $font-size-12;
    line-height: 1;
    padding-left: 12px;
  }
  > i {
    position: absolute;
    top: 10px;
    right: 12px;
    color: #939599;
  }
}

.category-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  overflow: auto;
  background: $white;
  padding: 16px;
  display: none;
  @include media-breakpoint-up(md) {
    position: static;
    padding: 0;
    display: block;
    overflow: visible;
  }
  .btn-closecate {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #bfbfbf;
    font-size: $font-size-24;
    line-height: 1;
  }
}
.category-item {
  background-color: #f7f7f7;
  border-radius: 4px;
  // padding: 20px 12px;
  padding: 15px 12px;
  .icon {
    filter: grayscale(1) brightness(0);
    width: 30px;
    height: 30px;
    img {
      width: 100%;
    }
  }
  .text {
    // width: calc(100% - 30px);
    font-size: 14px;
    font-weight: bold;
    // padding-left: 12px;
    width: 100%;
    font-style: normal;
    font-family: Roboto;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &:hover {
    background-color: rgba(223, 104, 42, 0.2);
    .icon {
      filter: grayscale(0);
    }
    .text {
      color: #cc9999;
    }
  }
}

.home-news {
  margin: 0 0 20px;
  @include media-breakpoint-up(xl) {
    margin: 100px 0px 40px;
  }
}

.home-cert {
  border-top: 1px solid #e8e8e8;
  padding-top: 40px;
  @include media-breakpoint-up(xl) {
    padding-top: 80px;
  }
  .headline-3 {
    text-align: center;
    color: #333333;
    font-style: normal;
    margin-bottom: 24px;
  }
  .cert-item {
    height: 80px;
    border: 1px solid #dadee4;
    border-radius: 8px;
    @include media-breakpoint-up(xl) {
      height: 100px;
    }
    img {
      height: 16px;
      @include media-breakpoint-up(xl) {
        height: 22px;
      }
    }
  }
  .cert-image {
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 60px;
    }
  }
}

.home-product {
  // margin-top: 40px;
  // @include media-breakpoint-up(xl) {
  //   margin-top: 60px;
  // }
  .product-nav {
    background: #FCF7F4;
    padding-top: 4px;
    margin-bottom: 20px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }
    .nav-tabs {
      border: none;
      .nav-link {
        // color: #8f8f90;
        font-weight: 600;
        width: 50%;
        text-align: center;
        padding: 10px 0;
        border: none;
        border-radius: 0;
        font-size: $font-size-14;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(xl) {
          padding: 15px 0;
          width: 364px;
          font-size: $font-size-18;
        }
        // &.active {
        //   color: $main;
        // }
      }
    }
  }
  .home-product-container {
    position: relative;
  }
  .btn-swiper-wrap {
    display: none;
    align-items: center;
    position: absolute;
    top: -103px;
    right: 0;
    @include media-breakpoint-up(xl) {
      display: flex;
    }
    .btn-swiper {
      width: 45px;
      height: 45px;
      display: flex;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      background: $white;
      color: #242424;
      + .btn-swiper {
        margin-left: 14px;
      }
    }
  }
}

[class*='product-swiper'] {
  overflow: visible;
  @include media-breakpoint-down(md) {
    .swiper-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .swiper-slide {
        width: calc(33% - 10px);
        margin-bottom: 20px;
        margin-right: 10px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .swiper-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .swiper-slide {
        width: calc(50% - 10px);
        margin-bottom: 20px;
        margin-right: 10px;
      }
    }
  }
}

.home-new-product {
  padding-bottom: 40px;
  @include media-breakpoint-up(xl) {
    padding-bottom: 80px;
  }
  .btn-seeall {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }
  .product-nav {
    // margin-top: 40px;
    background: #FCF7F4;
    padding-top: 18px;
    padding-bottom: 8px;
    margin-bottom: 20px; 
  }
}

.suusi-home-new-product {
  h2 {
    color: #333 !important;
    font-weight: 600 !important;
  }
}

.suusi-home-product {
  .trending-tabs {
    .nav-link {
      color: #333 !important;
      font-weight: 600 !important;
    }
  }
}

#content_inner {
  padding-bottom: 32px;
}