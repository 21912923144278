// headline

.headline-1 {
  @include headline($font-size-36);
}

.headline-2 {
  @include headline($font-size-18);
  @include media-breakpoint-up(xl) {
    @include headline($font-size-28);
  }
}

.headline-3 {
  @include headline($font-size-18);
  @include media-breakpoint-up(xl) {
    @include headline($font-size-24);
  }
}

.headline-4 {
}

.headline-5 {
}

.headline-6 {
}

// subtitle

.subtitle {
  @include subtitle($font-size-48);
}
.subtitle-1 {
  @include subtitle($font-size-36);
}
.subtitle-2 {
  @include subtitle($font-size-24);
}
.subtitle-3 {
  @include subtitle($font-size-18);
}

// font size
.font-size-12 {
  font-size: $font-size-12;
}
.font-size-14 {
  font-size: $font-size-14;
}
.font-size-16 {
  font-size: $font-size-16;
}
.font-size-20 {
  font-size: $font-size-20;
}
.font-size-24 {
  font-size: $font-size-24;
}

// font weight

.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

// data binding text
.label-value {
  font-size: $font-size-14;
  .value-row {
    display: flex;
    + .value-row {
      // margin-top: 6px;
    }
  }
  label {
    padding-right: 8px;
  }
  .new {
    color: $primary;
  }
  .old {
    color: #828282;
    text-decoration: line-through;
  }
}

.link {
  color: $primary;
  &:hover {
    text-decoration: underline;
  }
}

.page-name {
  font-size: $font-size-24;
  font-weight: 700;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: $font-size-32;
  }
}
