.about-banner {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  // &::before {
  //   content: '';
  //   display: block;
  //   background: rgba(black, 0.8);
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  // }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: brightness(20%);
  }
  .container {
    z-index: 1;
    .banner-title {
      color: white;
      font-weight: 700;
      margin-bottom: 14px;
      font-size: $font-size-32;
      line-height: 1.2;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }
    .banner-desc {
      color: $white;
      opacity: 0.8;
      font-weight: 400;
    }
  }
}
.about-intro {
  padding: 60px 0;
  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }
  .about-title-1 {
    font-size: $font-size-32;
    font-weight: 700;
    color: #1d2b4f;
    margin-bottom: 8px;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      line-height: 1.2;
    }
  }
  .about-title-2 {
    font-size: $font-size-18;
    font-weight: 600;
    color: #3d5a80;
    margin-bottom: 24px;
  }
  .about-title-3 {
    font-size: $font-size-14;
    color: #3d5a80;
  }
}
.about-reason {
  background: #e5e5e5;
  padding: 60px 0;
  .reason-header {
    font-size: $font-size-24;
    font-weight: 700;
    color: #1d2b4f;
    max-width: 500px;
    margin: 0 auto 40px;
    @include media-breakpoint-up(md) {
      font-size: $font-size-32;
    }
  }
  .col {
    &:nth-child(1) {
      .about-block {
        background: $white url(../imgs/l1.png) center / cover no-repeat;
      }
    }
    &:nth-child(2) {
      .about-block {
        background: $white url(../imgs/l2.png) center / cover no-repeat;
      }
    }
    &:nth-child(3) {
      .about-block {
        background: $white url(../imgs/l3.png) center / cover no-repeat;
      }
    }
  }
  .about-block {
    padding: 30px 40px;
    height: 100%;
    box-shadow: 14px 24px 72px rgba(102, 102, 102, 0.1);
    border-radius: 8px;
    .about-icon {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .subtitle-3 {
      color: #222222;
      line-height: 25px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .desc {
      font-size: $font-size-14;
      color: #666;
    }
  }
}
.about-product {
  padding: 60px 0;
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .headline-3 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .product-block {
    border: 1px solid #e9eff3;
    .product-img {
      padding: 6px;
      img {
        width: 100%;
      }
    }
    .product-caption {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      height: 260px;
      .product-name {
        font-size: $font-size-18;
        font-weight: 700;
        margin-bottom: 12px;
        flex-shrink: 0;
      }
      .product-desc {
        color: #666666;
        font-size: $font-size-14;
        flex: auto;
      }
      .product-btn {
        flex-shrink: 0;
      }
    }
  }
}

.product-group-btns {
  display: flex !important;
  #add_to_basket_form .btn-cart {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .wishlist-container {
    margin-left: 10px;
  }
  @media (max-width: 767px) {
    display: block !important;
    .wishlist-container {
      margin-left: 0;
      margin-top: 10px;
    }
    .btn-wishlist {
      width: 100%;
    }
    .btn-buy {
      margin-top: 10px;
    }
  }
}