.suusi-category-item-container {
  // margin-top: 64px;
  padding-top: 32px;
  padding-bottom: 20px;
}

.suusi-product-item-top-category {
  text-align: center;
}

.suusi-category-item-box {
  --number-category-item: 4;
  --category-item-gutter: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--category-item-gutter);
  margin-top: 16px;

  @include media-breakpoint-down(md) {
    --number-category-item: 2;
  }

  @include media-breakpoint-down(sm) {
    --number-category-item: 2;
  }
}

.suusi-category-item-box > div, .suusi-category-item {
  width: calc(
    (100% - var(--number-category-item) * var(--category-item-gutter)) /
      var(--number-category-item)
  );

  &-figcaption {
    height: 260px;
    border: 1px solid;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-name {
    text-align: center;
    text-decoration: underline;
    margin-top: 8px;
  }
}
