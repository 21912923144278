// CORE
@import 'core';

// COMPONENTS
@import 'components';

// SCREENS
@import 'screens';


.price_color {
    display: block;
    color: $success !important;
    font-weight: bold;
}


// #update_user_address .form-group.row:nth-child(9){
    // display:none;
// }

.basket-line-actions {
    display: flex;
}
.quick-add {
    padding: 0 !important;
}

.product-item {
    .quick-add {
        opacity: 0;
        margin-bottom: 5px;
    }
    .btn-block {
        width: 100%;
    }
    &:hover .quick-add {
        opacity: 1;
    }
}

.suusi-quick-add .btn-primary {
    background-color: transparent !important ;
    border: 0;
}

#new_shipping_address, #update_user_address {
    #id_title {
        display: none !important;
        & + label {
            display: none !important;
        }
    }
}

.personal-address-form form > .form-group {
    &:first-child {
        display: none !important;
    }
}
.personal-address-form form > .form-group:nth-child(9) {
    display: none !important;
}

.current-address-btn i {
    display: none;
    vertical-align: top;
}

.current-address-btn.btn-success i {
    display: inline !important;
}

.no-overflow {
    overflow: hidden;
}

// .simplebar-scrollbar::before {
//     background-color: red;
// }

.responsive-img {
    width: 100%;
}
.gjs-dashed {
    [draggable="true"]#home-products,
    [draggable="true"]#home-new-products {
        opacity: 0.7;
    }
    .home-products, .home-all-products, [data-gjs-type="category-product"], [data-gjs-type="suppliers-products"], [data-gjs-type="instagram-feed"], [data-gjs-type="item-ids-products"] {
        padding: 10px;
        background: gray;
        & > div {
            display: none;
        }
    }
    .home-products:after {
        content: "Home offering products";
    }
    .home-all-products:after {
        content: "Home all products";
    }
    [data-gjs-type="category-product"]:after {
        content: "Home category products";
    }
    [data-gjs-type="suppliers-products"]:after {
        content: "Products By Suppliers";
    }
    [data-gjs-type="instagram-feed"]:after {
        content: "Instagram feed by Behold";
    }
    #gjs-edit-field {
        box-shadow: inset 0 0 10px $primary;
        position: relative;
        &:after {
            content: "Edit zone";
            color: $primary;
            opacity: 0.8;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

}



// [data-gjs-editable="false"] {
//     opacity: 0.7;
// }
// #gjs-edit-field {
//     opacity: 1;
// }


#should_use_mail_for_marketingFormRow {
    display: flex !important;
    input[type='checkbox'] {
        margin-top: 15px;
    }
}

.payment-method-graphics {
    font-size: 30px;
    color: #013751;
    width: 100% !important;
    justify-content: right !important;
    .pm-image {
        margin-right: 5px;
    }
}

.payment-selection {
    .help-text {
        font-weight: 400;
    }
}

.product-gallery {
    margin-bottom: 15px;
}

.vero-toggler {
    cursor: pointer;
    border: 0;
    background: transparent;
    .vero-number {
        color: $primary;
        font-weight: bold;
    }
    .vero-text {
        font-size: 0.875rem;
        line-height: 20px;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}
.header.floating {
    .vero-toggler .vero-text {
        color: #cc9999;
    }
    .header .top-nav .item .text {
        color: #cc9999;
    }
}

.home-products-inner {
    .product-nav {
        background-color: #013751;
        padding-top: 0 !important;
    }
    h2 {
        color: white;
        padding-top: 18px;
        font-size: 1.125rem;
    }
    .nav-tabs {
        .nav-link.active {
            background-color: #ffcccc;
            color: #2d2d2d;
            font-weight: 600;
            margin: 0;
        }
        .nav-link {
            background-color: #013751;
            color: white;
            font-weight: 600;
            margin: 0;
        }
    }
    &.black_on_pink {
        h2 {
            color: #2d2d2d;
        }
        .product-nav {
            background-color: #013751;
        }
        .nav-tabs {
            .nav-link.active {
                background-color: #ffcccc;
                color: #2d2d2d;
            }
            .nav-link {
                background-color: #013751;
                color: white;
            }
        }
    }
    &.white_on_blue {
        h2 {
            color: white;
        }
        .product-nav {
            background-color: #013751;
        }
        .nav-tabs {
            .nav-link {
                background-color: #013751;
                color: white;
            }
            .nav-link.active {
                background-color: #ffcccc;
                color: #2d2d2d;
            }
        }
    }
}
.grapesjs-home-all-products, .home-category-products, .grapesjs-suppliers-products, .grapesjs-item-ids-products {
    .home-products-inner {
        &.white_on_blue {
            h2 {
                color: white;
            }
            .product-nav {
                background-color: #013751;
            }
        }
        &.black_on_pink {
            h2 {
                color: #2d2d2d;
            }
            .product-nav {
                background-color: #ffcccc;
            }
        }
    }
}

.recommended-products-tr {
    background: white;
    td {
        padding-top: 7px !important;
        background: none !important;
    }
    .recommended-products-container {
        // margin-left: 20px;
        h5 {
            font-size: $font-size-12;
            font-weight: 700;
            margin-bottom: 0;
            text-decoration: underline;
            cursor: pointer;
            display: inline-block;
        }
        .recommended-products-inner {
            display: none;
        }
        input[type='checkbox'] {
            display: none;
            &:checked ~ .recommended-products-inner {
                display: flex !important;
            }
            & + label {
                &::before {
                    content: ' ';
                    display: inline-block;

                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 5px solid currentColor;

                    vertical-align: middle;
                    margin-right: .7rem;
                    transform: translateY(-2px);

                    transition: transform .2s ease-out;
                }
            }
            &:checked + label::before {
                transform: rotate(90deg) translateX(-3px);
            }
        }
    }
    // .recommended-products-inner {
    //     display: flex;
    // }

    .recommended-product-item {
        margin-right: 5px;
        margin-top: 5px;
        padding: 5px;
        border: 1px solid #333;
        width: 33%;
        .item-img {
            display: flex;
            width: 70px;
            height: 70px;
            // text-align: center;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 100%;
            }
        }
        .item-caption {
            flex: 1;
            margin-bottom: 3px;
            .item-name {
                font-size: $font-size-12;
                font-weight: 700;
                margin-bottom: 0;
                @include textOverflow(1);
            }
            .item-sku {
                color: #939599;
                font-size: $font-size-12;
                @include textOverflow(2);
            }
        }
    }
}

main {
    padding-bottom: 30px;
}

iframe {
    max-width: 100%;
}

.category-item-grapesjs {
    .suusi-category-item-name {
        &:last-child {
            display: none;
        }
        &:first-child {
            display: block;
        }
    }
}

#gjs-edit-field {
    .category-item-grapesjs {

        .suusi-category-item-name {
            display: none !important;
            &:last-child {
                display: block !important;
            }
        }
    }
}

.range-slider.flat[style*='--value-b:20000'], .range-slider.flat[style*='--value-b: 20000'] {
    output:nth-child(4) {
        &::after {
            content: 'max' !important;
        }
    }
    .input-price #phigh {
        &::after {
            content: 'max' !important;
        }
    }
}


[theme="default"] .posti-destinations {
    display: none !important;
}

select.form-control {
    padding: 0 1rem;
}

.gjs-row .gjs-cell {
    max-width: 100%;
}


.menu-content-expanded.desktop-only {
    .menu-item {
        list-style: none;
        & > .menu-link {
            display: none;
        }
    }
}
