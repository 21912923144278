.suusi-footer .container {
  .suusi-footer-separator-line.second-separation-line {
    @include media-breakpoint-down(lg) {
    // @media (max-width: 768px) {
        display: none;
    }
  }
}

.suusi-footer-container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  @include media-breakpoint-down(lg) {
  // @media (max-width: 768px) {
    flex-direction: column;
  }
}

.suusi-footer-left {
  display: flex;
  gap: 32px;

  .suusi-footer-column:last-child {
    margin-left: 32px;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .suusi-footer-column:last-child {
      margin-left: 0px;
    } 
  }
}

.suusi-footer-column {
  max-width: 195px;
}

.suusi-footer-title {
  font-size: $font-size-14;
  margin-bottom: 8px;
}

.suusi-footer-list {
  font-size: $font-size-12;
}

.suusi-footer-item {
  margin-bottom: 8px;
}

.suusi-footer-right {
  max-width: 395px;
  @include media-breakpoint-down(sm) {
    padding-top: 32px;
    flex-direction: column;
    .suusi-footer-right-join-btn {
      margin-top: 12px;
      margin-left: 0;
    }
  }
  &-title {
    font-size: $font-size-16;
  }

  &-join-input {
    width: 210px;
    background: transparent;
    padding: 8px;
    border: 1px solid $white;
    outline: none;
    color: $white;

    &::placeholder {
      color: $white;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      color: $white;
    }
  }

  &-join-btn {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    padding: 7px;
    width: 110px;
    margin-left: 8px;
  }
}

.suusi-footer-separator-line {
  margin-top: 96px;
  height: 1px;
  background-color: #fff;
}

.suusi-footer-setting {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.suusi-footer-end-title,
.suusi-footer-copyright {
  color: $white;
  margin: 32px 0;
  margin-bottom: 0;
  text-align: center;
}

.suusi-footer-copyright {
  margin-top: 8px;
  // height: 48px;
  line-height: 48px;
  text-align: center;
  // background: $primary;
  color: $black;
  font-size: $font-size-12;
  @include media-breakpoint-down(sm) {
    padding: 0 32px; 
  }
}
