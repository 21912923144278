footer {
  // margin-top: 120px;
  background: #05324e;
  color: $white;
  font-size: $font-size-14;
  font-weight: 400;
  @include media-breakpoint-up(xl) {
    // margin-top: 140px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .footer-title {
    font-size: $font-size-16;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    b {
      font-weight: 700;
    }
  }

  .footer-col {
    position: relative;
    @include media-breakpoint-up(xl) {
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 176px;
        position: absolute;
        top: 0;
        left: -30px;
        background: #0f263a;
      }
    }
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid #0f263a;
      margin-bottom: 20px;
      &.no-border {
        border: none;
      }
    }
  }

  .footer-contact {
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
      border-bottom: 1px solid #0f263a;
    }
    li {
      position: relative;
      padding-left: 24px;
      + li {
        margin-top: 14px;
      }
      .icon {
        position: absolute;
        top: -2px;
        left: 0;
        width: 18px;
        text-align: center;
      }
      a {
        display: block;
        color: $white;
        line-height: 1.2;
      }
    }
  }

  .footer-link {
    margin-bottom: 20px;
    li {
      + li {
        margin-top: 14px;
      }
      a {
        display: block;
        color: $white;
        line-height: 1.2;
      }
    }
  }

  .copyright {
    margin-top: 30px;
    background: #0f263a;
    font-size: $font-size-12;
    font-weight: 400;
    text-transform: uppercase;
    color: #d4d4d4;
    text-align: center;
    padding: 13px 0;
  }
}
