.personal-section {
  background: #ffffff;
  border-bottom: 10px solid #efefef;
  margin: 0px -16px;
  padding: 16px;
  @include media-breakpoint-up(md) {
    border: 1px solid #e6e6e6;
    margin: 0;
    padding: 40px;
  }
}
.personal-headline {
  font-size: $font-size-18;
  font-weight: 600;
  margin-bottom: 30px;
}
.personal-form {
  border-bottom: 1px solid #e6e6e6;
}
.btn-personal-save {
  margin-top: 40px;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
    min-width: 190px;
  }
}

.box-info {
  background: #f7f7f7;
  border: 1px solid #dadee4;
  border-radius: 4px;
  padding: 30px 16px;
  position: relative;
  font-size: $font-size-14;
  .box-label {
    font-size: $font-size-12;
    color: #939599;
    background: $white;
    line-height: 1.2;
    padding: 0px 5px;
    display: inline-block;
    position: absolute;
    top: -8px;
    left: 15px;
  }
  .row-info {
    + .row-info {
      margin-top: 12px;
    }
    label {
      color: rgba($main, 0.6);
    }
  }
}

.address-book {
  .personal-tabs {
    margin: 0 -40px 30px;
    padding: 0px 40px;
  }
  .box-info {
    margin-bottom: 24px;
  }
}

.order-history {
  .history-item {
    border: 1px solid rgba(#e6e6e6, 0.4);
    + .history-item {
      margin-top: 16px;
    }

    .history-header {
      background: #f7f7f7;
      border: 1px solid #e6e6e6;
      margin: -1px -1px 0 -1px;
      padding: 12px 16px;
      font-size: $font-size-14;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-up(md) {
      }
      .info-cell {
        width: 50%;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #dadee4;
        @include media-breakpoint-up(md) {
          width: 110px;
          border-bottom: none;
          &:first-child {
            flex: auto;
          }
          &:nth-child(3) {
            width: 80px;
          }
          &:nth-child(5) {
            width: 140px;
          }
        }
        label {
          font-size: $font-size-12;
          color: #939599;
        }
      }
      .button-cell {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #dadee4;
        @include media-breakpoint-up(md) {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          border-bottom: none;
          margin: 0;
          padding: 0;
        }
        .btn-reorder {
          --bs-btn-padding-x: 50px;
          margin-top: 8px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
      .btn-toggle {
        position: absolute;
        text-align: right;
        font-size: $font-size-24;
        color: #939599;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%) rotateZ(90deg);
        @include media-breakpoint-up(md) {
          top: 20px;
          right: 0;
          left: 0;
          padding-right: 16px;
          transform: translateX(0);
        }
        &::before {
          display: inline-block;
          transition: all 0.3s ease;
        }
      }
      &.active {
        .btn-toggle {
          &::before {
            transform: scale(-1, 1);
            @include media-breakpoint-up(md) {
              transform: rotateZ(90deg);
            }
          }
        }
      }
    }

    .history-body {
      padding: 16px;
      display: none;

      .order-item {
        display: flex;
        font-size: $font-size-12;
        margin-bottom: 16px;
        .item-img {
          width: 70px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-up(md) {
            width: 45px;
            height: 45px;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .item-caption {
          flex: auto;
          display: flex;
          padding-left: 10px;
          flex-wrap: wrap;
          @include media-breakpoint-up(md) {
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
          }
          .item-name {
            flex: auto;
            width: 100%;
            @include media-breakpoint-up(md) {
              width: auto;
            }
          }
          .item-sku {
            color: #939599;
          }
          .item-amount {
            width: 120px;
          }
          .item-price {
            font-size: $font-size-14;
            width: 120px;
          }
        }
      }
    }

    .shipping-status {
      margin: 0px -16px -16px;
      padding: 16px;
      border-top: 1px solid rgba(#e6e6e6, 0.4);
      .shipping-headline {
        color: #939599;
      }
      .shipping-address {
        font-size: $font-size-14;
        padding-left: 24px;
      }
    }
  }
}

.badge {
  &.status {
    font-weight: 400;
    color: #337d58;
    background: #ffffff;
    border-radius: 4px;
    padding: 5px 8px;
  }
}

.search-user-wrapper {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
  }
}
.search-user {
  position: relative;
  flex: auto;
  > .form-control {
    border-radius: 0;
    padding: 7px 16px;
    padding-left: 40px;
  }
  .icon {
    position: absolute;
    top: 7px;
    left: 16px;
    color: #939599;
  }
}

.user-table {
  @include media-breakpoint-down(sm) {
    width: calc(100% + 32px);
    margin: 0px -16px;
  }
  th {
    &:nth-child(2) {
      width: 105px;
    }
    &:last-child {
      width: 150px;
    }
  }

  thead {
    tr {
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
    th {
      @include media-breakpoint-down(sm) {
        width: calc(100% - 100px);
        line-height: 1.2;
        display: flex;
        align-items: center;
        &:nth-child(4) {
          width: 110px;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
  tbody {
    tr {
      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0.5rem;
        position: relative;
        border-bottom: 6px solid $white;
      }
      td {
        @include media-breakpoint-down(sm) {
          display: block;
          border-bottom: none;
          padding: 0;
          &:nth-child(3) {
            border-top: 1px solid #e6e6e6;
            margin-top: 0.5rem;
            padding-top: 0.5rem;
          }
          &:nth-child(4) {
            position: absolute;
            padding: 0.5rem;
            width: 110px;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }

  .btn-showaction {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 30px;
    text-align: center;
    z-index: 10;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .table-action {
    @include media-breakpoint-down(ms) {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(26, 24, 24, 0.25);
      border-radius: 2px;
      padding: 0px 16px;
      position: absolute;
      top: 30px;
      right: 0.5rem;
      z-index: 20;
      display: none;
    }
  }

  .btn-link {
    + .btn-link {
      border-top: 1px solid #e6e6e6;
      @include media-breakpoint-up(md) {
        border-top: none;
        margin-left: 12px;
      }
    }
    @include media-breakpoint-down(ms) {
      display: block;
      padding: 8px 0;
    }
  }
}
