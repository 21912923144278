.product-section {
  background: $white;
  padding: 16px;
  margin: 0px -16px 10px;
  @include media-breakpoint-up(md) {
    margin: 0px 0px 24px;
    padding: 30px;
  }
  .headline-3 {
    margin-bottom: 16px;
  }
}

.product-detail {
  @extend .product-section;
  .product-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
    img {
      max-width: 90%;
    }
  }
  .product-name {
    font-size: $font-size-24;
    @include media-breakpoint-up(md) {
      font-size: $font-size-34;
    }
  }
  .product-sku {
    color: #828282;
    border-left: 1px solid #bdbdbd;
    padding-left: 12px;
    margin-left: 12px;
  }
  .product-description {
    font-size: $font-size-14;
    @include media-breakpoint-up(md) {
      font-size: $font-size-16;
    }
  }
  .product-price {
    font-size: $font-size-18;
    color: #828282;
    line-height: 1;
    b {
      color: $primary;
      font-size: $font-size-32;
    }
    .vat {
      color: $main;
      margin-top: 8px;
    }
  }

  .product-amount {
    label {
      color: #939599;
      display: block;
      margin-bottom: 8px;
    }
    .input-amount {
      display: flex;
    }
  }

  .product-total-amount {
    font-size: $font-size-14;
    font-weight: 500;
  }

  .btn-cart {
    @include media-breakpoint-up(md) {
      width: 250px;
    }
  }

  .btn-buy {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 250px;
    }
  }
}

.product-deal {
  @extend .product-section;
  .deal-box {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  .deal-list {
    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex: auto;
      flex-wrap: nowrap;
    }
    .col {
      @include media-breakpoint-up(md) {
        flex: 0 1 auto;
      }
      &:first-child {
        @include media-breakpoint-up(md) {
          position: relative;
          margin-right: 30px;
          &::after {
            content: '\2b';
            font-family: $fontawesome;
            font-weight: 900;
            font-size: $font-size-32;
            position: absolute;
            top: 45%;
            left: 100%;
          }
        }
      }
    }

    .deal-item {
      position: relative;
      padding-left: 30px;
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
      .form-check {
        position: absolute;
        z-index: 1;
        top: 26px;
        left: 0;
        @include media-breakpoint-up(md) {
          top: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        .product-item {
          display: flex;
          .product-img {
            width: 80px;
            height: 80px;
            border: 1px solid #333;
          }
          .product-caption {
            width: calc(100% - 80px);
            padding-left: 12px;
            .product-price {
              margin-bottom: 0;
            }
            .product-rating {
              font-size: $font-size-12;
            }
          }
        }
      }
    }
  }
  .deal-checkout {
    background: rgba(#f5f5f5, 0.5);
    margin: 0px -16px;
    padding: 24px 16px;
    @include media-breakpoint-up(md) {
      background: none;
      width: 194px;
      border-left: 1px solid #bdbdbd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 0 0 0 30px;
    }
  }
}

.product-technical {
  @extend .product-section;
  .content {
    max-width: 100%;
    font-size: $font-size-14;
    // max-height: 350px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      max-height: unset;
    }
    &.show {
      max-height: unset;
    }
  }
  .btn-viewmore {
    border-top: 1px solid #d1d1d1;
    padding: 16px 0;
    font-size: $font-size-14;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: calc(100% + 1px);
      left: 0;
      right: 0;
      height: 100px;
      background: linear-gradient(rgba($white, 0), $white);
    }
    @include media-breakpoint-up(md) {
      font-size: $font-size-16;
    }
  }
  h2 {
    line-height: 1rem !important;
    margin: 0;
    font-size: 0.75rem !important;
  }
}

.product-review {
  @extend .product-section;
  .review-panel {
    background: #fafafa;
    padding: 30px;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rating-block {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @include media-breakpoint-up(md) {
        width: 200px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
      }
      .rating-text {
        color: $primary;
        font-weight: 600;
        font-size: $font-size-24;
        padding-right: 16px;
        @include media-breakpoint-up(md) {
          font-size: $font-size-42;
          padding-right: 0;
        }
      }
    }
    .rating-analysis {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(md) {
        width: 410px;
        border-left: 1px solid #828282;
        border-right: 1px solid #828282;
      }
      .rating-row {
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        @include media-breakpoint-up(md) {
          width: auto;
        }

        + .rating-row {
          margin-top: 6px;
        }
        .product-rating {
          font-size: $font-size-12;
          li {
            + li {
              margin-left: 4px;
            }
          }
        }
        .progress {
          flex: auto;
          margin-left: 12px;
          @include media-breakpoint-up(md) {
            width: 176px;
          }
          .progress-bar {
            border-radius: 1000px;
          }
        }
        .rating-amount {
          color: #828282;
          font-size: $font-size-12;
          font-weight: 600;
          margin-left: 12px;
        }
      }
    }
    .write-review {
      width: 100%;
      @include media-breakpoint-up(md) {
        padding-left: 60px;
        width: auto;
      }
      .total-review {
        font-size: $font-size-14;
        font-weight: 500;
        margin-bottom: 4px;
      }
      .btn-review {
        width: 100%;
        margin-top: 16px;
        @include media-breakpoint-up(md) {
          width: 250px;
          margin-top: 0;
        }
      }
    }
  }

  .comment-list {
    .comment-item {
      padding: 40px 0 20px;
      + .comment-item {
        border-top: 1px solid #bdbdbd;
      }
      .author {
        font-size: $font-size-18;
        font-weight: 700;
      }
      time {
        color: #828282;
        font-size: $font-size-12;
        border-left: 1px solid #bdbdbd;
        padding-left: 12px;
        margin-left: 12px;
      }
      .content {
        margin-top: 16px;
        font-size: $font-size-14;
        max-width: 790px;
      }
    }
  }
}

.product-similar {
  @extend .product-section;
}

.attribute-select {
  .attribute-option {
    padding: 5px 10px 5px 11px;
    text-align: left;
    white-space: normal;
    border: 1px solid #E0E0E0;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 4px;
    margin-top: 4px;
    position: relative;
    &--selected {
      color: $primary;
      border-color: #E47911;
    }
    &--unavailable {
      color: #bdbdbd;
      border: 1px dashed #828282;
    }
  }
}
