%flexCenter {
  display: flex;
  align-items: center;
}

.suusi-header {
  // background: $primary;
  position: sticky;
  top: -38px;
  z-index: 1000;
  // padding-bottom: 8px;

  @include media-breakpoint-down(lg) {
    top: 0px;
    font-size: $font-size-14;
    // padding-bottom: 6px;
  }


  a:hover {
    color: #fff;
  }
  .header-action-user {
    justify-content: end;
    @include media-breakpoint-up(md) {
      width: 300px;
    }
  }

  .header-setting {
    @extend %flexCenter;
    justify-content: space-between;
    padding-top: 8px;

    @include media-breakpoint-down(lg) {
      padding-top: 6px;
    }

    @include media-breakpoint-down(lg) {
      font-size: $font-size-12;
    }
  }

  .header-action {
    @extend %flexCenter;
    margin-top: 16px;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      margin-top: 4px;
      margin-left: 26px;
      margin-bottom: 4px;
    }

    .mobile-menu-icon {
      display: none;

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    &-search {
      @extend %flexCenter;
      gap: 8px;

      .search-input {
        border: none;
        padding: 0 16px;
        outline: none;
        background: transparent;
        border-bottom: 1px solid;
        width: 300px;
        font-size: $font-size-14;

        @include media-breakpoint-down(lg) {
          width: 200px;
        }

        @include media-breakpoint-down(md) {
          width: 100px;
          display: none;
        }

        &::placeholder {
          color: $black;
          opacity: 1; /* Firefox */
          font-size: $font-size-14;
        }

        &::-ms-input-placeholder {
          /* Edge 12-18 */
          color: $black;
          font-size: $font-size-14;
        }
      }
    }

    .logo {
      translate: -125px;

      @include media-breakpoint-down(lg) {
        translate: -42px;
      }

      @include media-breakpoint-down(md) {
        height: 23px;
        translate: 0;
      }
    }

    &-user {
      @extend %flexCenter;
      gap: 8px;
      cursor: pointer;
    }
  }

  .header-navbar {
    margin-top: 16px;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      // display: none;
      position: absolute;
      top: 38px;
      left: 30px;
      // background: $primary;
      margin-top: 0;
      border-radius: 0;
      nav {
        display: none;
      }
      .header-menu .menu-container {
        justify-content: left;
      }
      .btn-menu {
        padding: 0;
        height: auto !important;
      }
      .btn-menu .icon-menu {
        width: 15px;
        height: 10px;
      }
      .menu-product > .menu {
        margin: 0;
        top: 64px;
        overflow-y: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      inset: 0 20% 0 0;
    }

    .menu-list {
      @extend %flexCenter;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px !important;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 15px;
      }

      @include media-breakpoint-down(xl) {
        gap: 12px;
      }
    }
  }

  .navbar-overlay {
    display: none;

    @include media-breakpoint-down(lg) {
      display: none;
      position: fixed;
      inset: 0;
      background: rgba($color: #000000, $alpha: 0.4);
    }
  }

  .mobile-only {
    &.menu-content--base {
      .menu-list a {
        display: block;
        margin-left: 0 !important;
        & + a {
          margin-top: 12px !important;
        }
      }
    }
  }
}

.language-setting {
  @extend %flexCenter;
  // gap: 8px;
  cursor: pointer;

  &-flag {
    // width: 24px;

    @include media-breakpoint-down(md) {
      width: 16px;
    }
  }
  @include media-breakpoint-down(lg) {
    .dropdown-toggle {
      padding: 0;
    }
  }
}

.header-action-search {
  z-index: 2;
}

.suusi-header-action-search {
  form {
    @include media-breakpoint-down(lg) {
      max-width: 700px;
      margin: 0 auto;
    }
  }
  #header-search-icon {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 63px;
    z-index: 1;
    width: 100%;
  }
}