@import 'screens/home';
@import 'screens/auth';
@import 'screens/notify';
@import 'screens/term-policy';
@import 'screens/product';
@import 'screens/product-detail';
@import 'screens/about';
@import 'screens/cart';
@import 'screens/payment';
@import 'screens/personal';
@import 'screens/about2';

