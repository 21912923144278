// ***COLORS***

// color list
$primary-90: #d3d5f6;
$primary-80: #a7abed;
$primary-70: #7a81e4;
$primary-60: #4e57db;
$primary-50: #222dd2;
$primary-40: #1b24a8;
$primary-30: #141b7e;
$primary-20: #0e1254;
$primary-10: #07092a;

$secondary-90: #ebfaff;
$secondary-80: #d6f4fe;
$secondary-70: #bfeefe;
$secondary-60: #aae8fd;
$secondary-50: #95e4fd;
$secondary-40: #77b5ca;
$secondary-30: #598898;
$secondary-20: #3d5b65;
$secondary-10: #1f2e33;

$neutral-90: #d4d3dc;
$neutral-80: #a9a6ba;
$neutral-70: #7f7a97;
$neutral-60: #544d75;
$neutral-50: #292152;
$neutral-40: #211a42;
$neutral-30: #191431;
$neutral-20: #100d21;
$neutral-10: #080710;

$error-90: #ffdad4;
$error-80: #ffb4a9;
$error-70: #ff897a;
$error-60: #ff5449;
$error-50: #dd3730;
$error-40: #ba1b1b;
$error-30: #930006;
$error-20: #680003;
$error-10: #410001;

// main colors
$black: #000;
$white: #fff;
$main: #333;

$primary: #cc9999;
$secondary: #fcf0ea;
$blue: #013751;
$blue-2: #05567D;
$neutral: $neutral-50;
$error: #DF2A2A;
$success: #337D58;

// grayscale
$gray: #7E7E7E;
$lightgray: #DADEE4;

$input-border: #DADEE4;

// gradient
$glorian-gradient: linear-gradient(to right, rgba(7, 9, 42, 0) 0%, rgba(7, 9, 42, 0.75) 50%, rgba(7, 9, 42, 0) 100%);
$menu-gradient: linear-gradient(rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 75) 0%);
$menu-gradient2: linear-gradient(to right, rgba(34, 35, 210, 0) 0%, rgba(34, 35, 210, 100) 50%, rgba(34, 35, 210, 0) 100%);

// ***TYPOGRAPHY***

// font size
$font-size-60: 3.75rem;
$font-size-48: 3rem;
$font-size-42: 2.625rem;
$font-size-36: 2.25rem;
$font-size-34: 2.125rem;
$font-size-32: 2rem;
$font-size-28: 1.75rem;
$font-size-24: 1.5rem;
$font-size-20: 1.25rem;
$font-size-18: 1.125rem;
$font-size-16: 1rem;
$font-size-14: 0.875rem;
$font-size-13: 0.8125rem;
$font-size-12: 0.75rem;
$font-size-10: 0.625rem;

// font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// ***SHADOW***

$shadow-1: 0px 1px 1px rgba(0, 0, 0, 0.2);
$shadow-2: 0px 1px 8px rgba(0, 0, 0, 0.2);
$shadow-3: 0px 1px 20px rgba(0, 0, 0, 0.2);
$shadow-4: 0px 1px 1px 0px 1px 40px rgba(0, 0, 0, 0.2);
$shadow-5: 0px 1px 80px rgba(0, 0, 0, 0.2);

// ***BACKGROUND***

$blur: blur(24px);

$opacity-25: 0.25;
$opacity-50: 0.5;
$opacity-75: 0.75;
$opacity-100: 1;

// ***SPACING***

$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-7: 1.75rem;
$spacing-8: 2rem;
$spacing-9: 2.5rem;
$spacing-10: 3rem;
$spacing-11: 4rem;
$spacing-12: 5rem;

// ***BORDER RADIUS***

$radius-4: 4px;
$radius-8: 8px;
$radius-16: 16px;

$fontawesome: 'Font Awesome 6 Pro';
