// header simple
.header-simple {
  background: $white;
  padding: 16px 0;
  margin-bottom: 24px;
  @include media-breakpoint-up(md) {
    padding: 30px 0;
    margin-bottom: 0;
  }
  .logo {
    a {
      img {
        height: 46px;
      }
    }
  }
}

.auth-header {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    border-bottom: 1px solid #c5c6c8;
    margin-top: 15px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(lg) {
    display: block;
    border-bottom: 1px solid #c5c6c8;
    margin-top: 60px;
    padding-bottom: 16px;
    margin-bottom: 80px;
  }
}

.auth-screen {
  // padding-bottom: 32px;
}

.login-container {
  border-bottom: 1px solid #c5c6c8;
  padding-bottom: 40px;
  margin-bottom: 40px;
  @include media-breakpoint-up(md) {
    padding-right: 100px;
    border-bottom: none;
    border-right: 1px solid #c5c6c8;
  }
  .headline-3 {
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 20px;
      margin-bottom: 14px !important;
    }
  }
}
.login-col-right {
  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }
}

.auth-form {
}

.btn-register {
  margin-top: 20px;
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
  i {
    margin-left: 16px;
  }
}

.register-form {
  max-width: 730px;
  @include media-breakpoint-up(md) {
    // margin: 90px auto 230px;
  }
  .form-group {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }
  .form-label {
    color: $black;
    margin-top: 12px;
  }
  .headline-3 {
    // margin-top: 6px;
    margin-bottom: 8px;
  }
  .form-check {
    + .form-check {
      margin-top: 24px;
    }
  }

  .form-button {
    @include media-breakpoint-down(lg) {
      .col-12 {
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
        }
      }
    }
  }

  .btn-back {
    @include media-breakpoint-up(lg) {
      --bs-btn-padding-x: 0px;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      border: 1px solid $main;
      margin-top: 12px;
      &:hover,
      &:focus {
        color: $white;
        background: $main;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba($main, 0.5);
      }
    }
  }
}
