.sidebar-filter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background: $white;
  padding: 16px;
  display: none;
  @include media-breakpoint-up(lg) {
    background: #f7f7f7;
    position: static;
    padding: 0 16px 16px;
    overflow: visible;
    display: block;
  }
  .btn-closefilter {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #bfbfbf;
    font-size: $font-size-24;
    line-height: 1;
  }
  .filter-content {
    height: calc(100vh - 195px);
    overflow: auto;
    margin: 0px -16px;
    padding: 0px 16px;
    @include media-breakpoint-up(lg) {
      height: auto;
      overflow: visible;
      margin: 0;
      padding: 0;
    }
  }
  .btn-clear {
    width: 100%;
    font-size: $font-size-16;
    font-weight: 600;
    border: 1px solid #d9d9de;
    border-radius: 4px;
    padding: 10px 16px;
    text-align: center;
    background: none;
    margin-top: 16px;
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
  }
}
.filter-title {
  text-transform: capitalize;
  font-size: $font-size-16;
  font-weight: 500;
  padding: 16px 0;
  &.collapse-title {
    position: relative;
    &::after {
      content: '\f077';
      font-family: $fontawesome;
      font-weight: 300;
      position: absolute;
      top: 16px;
      right: 0;
    }
    &.collapsed {
      &:after {
        transform: scale(1, -1);
      }
    }
    + .collapse {
      max-height: 300px;
      // margin: 0px -16px;
      // padding: 0px 16px;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        width: 5px;
      }
      &::-webkit-scrollbar{
        background: #eee;
      }
      &::-webkit-scrollbar-track{
        background: #eee;
      }
      &::-webkit-scrollbar-thumb{
        background: #aaa;
      }
    }
  }
}
.filter-price {
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  .input-price {
    background: #ffffff;
    border: 1px solid #e5e5ea;
    border-radius: 4px;
    display: flex;
    margin: 30px 0px 0;
    input {
      width: 50%;
      border: none;
      background: none;
      font-size: $font-size-14;
      padding: 9px;
      + input {
        border-left: 1px solid #e5e5ea;
      }
    }
  }
}

.filter-checkbox {
  + .filter-checkbox {
    border-top: 1px solid #e0e0e0;
  }
  .collapse {
    padding-bottom: 16px;
  }
  .form-check {
    + .form-check {
      margin-top: 10px;
    }
  }
}

.filter-checkbox.filter-small {
  // + .filter-checkbox {
  //   border-top: 1px solid #e0e0e0;
  // }
  .collapse {
    max-height: 150px;
  }
  // .form-check {
  //   + .form-check {
  //     margin-top: 10px;
  //   }
  // }
}

.product-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dadee4;
  .btn-openfilter {
    background: #f7f7f7;
    border: 1px solid #dadee4;
    border-radius: 4px;
    font-size: $font-size-14;
    line-height: 21px;
    padding: 8px 16px;
    flex: auto;
    text-align: center;
    margin-right: 12px;
    @include media-breakpoint-up(md) {
      width: auto;
      flex: none;
    }
  }
  .sort-by {
    flex: 1 0 150px;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      flex: none;
    }
    label {
      margin-right: 12px;
    }
    select {
      font-size: $font-size-14;
      border: 1px solid #dadee4;
      border-radius: 4px;
      padding: 8px $form-select-indicator-padding 8px $form-select-padding-x;
    }
  }
}
