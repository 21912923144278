.breadcrumb {
  --bs-breadcrumb-divider-color: #939599;
  --bs-breadcrumb-item-active-color: #464646;
  font-size: $font-size-13;
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  // display: block;
  @include media-breakpoint-up(xl) {
    padding-top: 25px;
  }
  .breadcrumb-item {
    font-size: $font-size-12;
    @include media-breakpoint-up(md){
      font-size: $font-size-16;
    }
    + .breadcrumb-item::before {
      content: '\f178  ';
      font-family: $fontawesome;
      font-weight: 300;
      display: contents;
      margin-right: 5px;
    }
  }
}
