.about-maintenance {
  background: #5c727c url(../imgs/aboutctbg.png) bottom left no-repeat;
  padding-top: 60px;
  background-size: 300px auto;
  background-position-y: bottom;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    background-size: 550px auto;
  }
  .caption {
    color: $white;
    .about-title {
      line-height: 1.4;
      font-weight: 700;
      font-size: $font-size-28;
      margin-bottom: 24px;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
        line-height: 1.2;
        margin-top: 80px;
      }
    }
    .desc {
      font-size: $font-size-18;
    }
  }
  .about-pic {
    text-align: right;
    padding-top: 20px;
    @include media-breakpoint-up(md) {
      text-align: center;
      padding-top: 0;
    }
    img {
      width: 240px;
      margin-right: -30px;
      @include media-breakpoint-up(md) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .maintenance-contact {
    position: relative;
    z-index: 5;
    background: url(../imgs/ctbg.png) top right no-repeat;
    background-size: 270% 100%;
    padding: 50px 0;
    margin-top: -40px;
    @include media-breakpoint-up(md) {
      background-size: 100% 100%;
      padding: 70px 0 60px;
      margin-top: 0;
    }
    ul {
      li {
        font-size: $font-size-20;
        color: #2a2b33;
        display: flex;
        align-items: center;
        + li {
          margin-top: 10px;
        }
        .icon {
          width: 32px;
          text-align: center;
          margin-right: 12px;
        }
      }
    }
  }
}
.about-helper {
  @extend .about-intro;
  .helper-icon {
    img {
      max-width: 350px;
    }
  }
}
.about-support {
  background: #f7f8fc;
  padding: 60px 0;
  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }
  .support-img {
    img {
      max-width: 350px;
    }
  }
  .support-caption {
    .support-header {
      font-size: $font-size-32;
      font-weight: 700;
      margin-bottom: 24px;
      color: #1d2b4f;
    }
    .support-desc {
      font-size: $font-size-14;
      color: #3d5a80;
      ul {
        list-style-type: disc;
        padding-left: 30px;
      }
    }
  }
}
.about-pdf {
  padding: 60px 0;
  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }
  .download-title {
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: #1d2b4f;
    max-width: 550px;
    margin: 0 auto 30px;
    font-size: $font-size-24;
    @include media-breakpoint-up(md) {
      font-size: $font-size-32;
    }
  }
  .pdf-list {
    max-width: 760px;
    margin: 0 auto;
  }
  
}
.pdf-block {
  display: flex;
  padding: 14px 0;
  align-items: center;
  + .pdf-block {
    border-top: 1px solid rgba(178, 188, 202, 0.3);
  }
  .icon {
    flex-shrink: 0;
    width: 30px;
    margin-right: 12px;
    img {
      max-width: 100%;
    }
  }
  .file-name {
    flex: auto;
    font-size: $font-size-18;
    font-weight: 600;
  }
  .view-link {
    a {
      font-size: 16px;
      > i {
        margin-right: 8px;
      }
    }
  }
}
.about-contact {
  position: relative;
  color: $white;
  margin-bottom: -120px;
  @include media-breakpoint-up(xl) {
    margin-bottom: -140px;
  }
  .logo{
    position: absolute;
    top: 40px;
    img {
      filter: grayscale(1) brightness(500%);
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .contact-banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 0;
    @include media-breakpoint-up(lg) {
      padding: 180px 0;
    }
  }
  .contact-title-1 {
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 24px;
    flex-shrink: 0;
    font-size: 2.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
  }
  .contact-title-2 {
    font-size: $font-size-18;
    flex: auto;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-24;
    }
  }
  .social-connect {
    font-size: $font-size-14;
    flex-shrink: 0;
    .social-link {
      a {
        font-size: $font-size-32;
        + a {
          margin-left: 16px;
        }
      }
    }
  }

  .contact-detail {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(40px);
    padding: 60px 16px;
    margin: 0px -16px;
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 220px 0;
      padding-left: 100px;
    }
    .contact-block {
      color: #e1e1e7;
      + .contact-block {
        margin-top: 60px;
      }
      .contact-subtitle {
        font-size: $font-size-18;
        font-weight: 700;
        margin-bottom: 16px;
      }
      ul.contact-info {
        li {
          padding-left: 30px;
          position: relative;
          + li {
            margin-top: 14px;
          }
          > i {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
