.suusi-instagram-item {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
}

.suusi-instagram-item-width2 {
  width: 400px;
  height: 400px;
}

.suusi-instagram-desc {
  font-size: $font-size-12;
  margin-top: 32px;
  margin-bottom: 32px;
}
