.header-menu {
  background: $blue;
  .menu-container {
    display: flex;
    position: relative;
  }
}
.menu-product {
}

nav.site-navigation {
  flex: auto;
  margin-left: 16px;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-breakpoint-up(xl) {
      justify-content: flex-start;
    }
    .site-navigation-item {
      min-width: 40px;
      min-height: 40px;
      margin-left: 24px;
      @include media-breakpoint-up(xl) {
        margin-left: 40px;
      }

      a {
        display: block;
        color: $white !important;
        font-size: $font-size-12;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 20px;
        padding: 10px 0;
        @include media-breakpoint-up(xl) {
          font-size: $font-size-14;
          padding: 14px 0;
        }
      }
    }
  }
}

.btn-menu {
  display: flex;
  align-items: center;
  color: $white;
  background: $blue-2;
  font-size: $font-size-18;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px 20px;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: -16px;
  height: 40px;
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    height: auto;
  }
  &:hover {
    background: lighten($blue-2, 5%);
  }
  .text {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .icon-menu {
    width: 20px;
    height: 15px;
    display: flex;
    align-items: center;
    position: relative;
    @include media-breakpoint-up(lg) {
      margin-left: 16px;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $white;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transition: all 0.2s ease-in-out;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $white;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.2s ease-in-out;
    }
    span {
      display: block;
      width: 100%;
      height: 1px;
      background: $white;
      transition: all 0.2s ease-in-out;
    }
    &.active {
      &::before {
        top: 50%;
        transform: translateY(-50%) rotateZ(45deg);
      }
      &::after {
        bottom: 50%;
        transform: translateY(50%) rotateZ(-45deg);
      }
      span {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.menu {
  position: absolute;
  z-index: 9999;
  font-size: $font-size-14;
  display: none;
  background: $white;
  left: 0;
  @include media-breakpoint-down(lg) {
    width: auto;
    // top: 80px;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background: $white;
    position: fixed;
  }
  ul.menu-content {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    background: $white;
    padding: 16px 0;
    &.menu-content--level-0, &.menu-content--level-1 {
      left: 300px;
      display: none;
      @include media-breakpoint-down(xl) {
        & {
          left: 240px;
        }
      }
    }
    &.menu-content--level-0, &.menu-content--level-1, &.menu-content--level-2 {
      height: 100%;
      background-color: transparent;
    }
    &.menu-content--level-0 {
      width: 100%;
      min-width: 800px;
      max-width: 800px;
      // height: auto;
      // padding: 0px 10px 10px 10px;
      background: white;

      -webkit-column-width: 170px;
      -moz-column-width: 170px;
      o-column-width: 170px;
      -ms-column-width: 170px;
      column-width: 170px;
      position: absolute;
      height: 600px;
      overflow: auto;
    //   position: fixed;
    //   top: 107px;
    //   height: 500px;
    //   left: 432px;
      @include media-breakpoint-down(xl) {
        & {
          min-width: 600px;
          max-width: 600px;
        }
        // width: auto;
        // top: 80px;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // overflow: auto;
        // background: $white;
        // position: fixed;
      }
      @include media-breakpoint-down(lg) {
        & {
          min-width: 100%;
          max-width: 100%;
          width: 100%
        }
        // width: auto;
        // top: 80px;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // overflow: auto;
        // background: $white;
        // position: fixed;
      }
    }
    &.menu-content--level-0 > .menu-item, &.menu-content--level-0 > .menu-parent-item {
      width: 100%;
      display: inline-block;
      background-color: white !important;
      vertical-align: top;
      .menu-link, .menu-parent-link {
        color: $blue;
        font-weight: bold;
      }
      .menu-content-open--level-1 {
        background: white !important;
        .menu-item, .menu-parent-item {
          border-top: none !important;
          background: white !important;
          .menu-link, .menu-parent-link {
            color: $main !important;
            background: white !important;
            font-weight: normal !important;
            padding: 2px 16px;
            &:hover {
              text-decoration: underline !important;;
            }
          }
        }

      }
    }
    // @include media-breakpoint-up(lg) {
    //   width: 1100px;
    //   padding: 0;
    //   background: #013751;
    //   &.menu-content--base {
    //     overflow-x: hidden;
    //     // overflow-y: scroll;
    //     height: 600px;
    //     box-shadow: inset -800px 0px 0px #fff;
    //   }
    // }
    @include media-breakpoint-up(xl) {
      width: 1100px;
      padding: 0;
      background: #013751;
      &.menu-content--base {
        overflow-x: hidden;
        // overflow-y: scroll;
        height: 600px;
        box-shadow: inset -800px 0px 0px #fff;
      }
    }
    @include media-breakpoint-up(lg) {
      width: 840px;
      // min-width: 600px;
      // max-width: 600px;
      padding: 0;
      background: #013751;
      &.menu-content--base {
        overflow-x: hidden;
        // overflow-y: scroll;
        height: 600px;
        // box-shadow: inset -560px 0px 0px #fff;
      }
    }
    @include media-breakpoint-up(xl) {
      width: 1100px;
      // min-width: 600px;
      // max-width: 600px;
      padding: 0;
      background: #013751;
      &.menu-content--base {
        overflow-x: hidden;
        // overflow-y: scroll;
        height: 600px;
        // box-shadow: inset -560px 0px 0px #fff;
      }
    }
    li.menu-item, li.menu-parent-item {
      position: relative;
      // background: #5c727c;
      @include media-breakpoint-up(lg) {
        position: static;
      }
      @include media-breakpoint-down(lg) {
        &::after {
          content: '\f054';
          font-family: $fontawesome;
          font-weight: 900;
          font-size: 14px;
          position: absolute;
          right: 15px;
          top: 10px;
        }
      }
      &.mobile-expanded {
        // background: #013751; 
        color: white;
        @include media-breakpoint-down(lg) {
          &::after {
            content: '\f078';
            font-family: $fontawesome;
            font-weight: 900;
            font-size: 14px;
            position: absolute;
            right: 15px;
            top: 10px;
          }
        }
        & .menu-parent-link {
          color: $main;
          
        }
        &::after {
          color: $main;
        }
        & > .menu-content {
          display: block;
          position: relative;
          height: auto;
          left: 0;
          column-width: auto;
          padding-top: 0;
          padding-left: 15px;
        }
        // & > .menu-link {
        // }
      }
      &.active {
        background: white;
        & a {
          color: #013751 !important;
        }
      }
      + li.menu-item, + li.menu-parent-item {
        border-top: 1px solid rgba(#000, 0.1);
        @include media-breakpoint-up(lg) {
          border-top: 1px solid rgba(#ffffff, 0.1);
        }
      }
      a.menu-link, a.menu-parent-link {
        display: inline-block;
        padding: 8px 16px;
        &:hover {
          text-decoration: underline !important;
        }
        @include media-breakpoint-up(lg) {
          color: $white;
        }
      }
      .btn-openchild {
        display: block;
        position: absolute;
        top: 10px;
        right: 16px;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
      .child-menu {
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 0;
        color: $main;
        background: $white;
        z-index: 10;
        padding: 16px;
        transform: translateX(100%);
        transition: all 0.3s ease-in-out;
        &.open {
          transform: translateX(0);
        }
        @include media-breakpoint-up(lg) {
          position: absolute;
          display: none;
          padding: 16px 32px;
          top: 0;
          left: 300px;
          right: 0;
          bottom: 0;
          transform: translateX(0);
          flex-wrap: wrap;
        }

        .btn-closechild,
        .btn-closesub {
          font-size: $font-size-14;
          color: #2f80ed;
          margin-bottom: 16px;
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
        .child-name,
        .sub-name {
          font-size: $font-size-14;
          font-weight: 700;
          background: rgba(218, 222, 228, 0.3);
          border-left: 5px solid $primary;
          padding: 12px 20px;
          margin: 0px -16px 16px;
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }

        .child-item {
          position: relative;
          + .child-item {
            border-top: 1px solid rgba($black, 0.1);
          }
          @include media-breakpoint-up(lg) {
            width: 33.33%;
            padding: 0px 20px;
            + .child-item {
              border: none;
            }
          }
        }
        .btn-opensub {
          display: block;
          position: absolute;
          top: 10px;
          right: 16px;
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }

        .child-link {
          display: block;
          padding: 11px 0;
          @include media-breakpoint-up(lg) {
            color: $primary;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0;
            margin-bottom: 12px;
          }
        }
        .sub-menu {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 20;
          background: $white;
          padding: 16px;
          transform: translateX(100%);
          transition: all 0.3s ease-in-out;
          &.open {
            transform: translateX(0);
          }
          @include media-breakpoint-up(lg) {
            display: block;
            position: static;
            transform: translateX(0);
            padding: 0;
          }
          ul.sub-content {
            li.sub-item {
              + li.sub-item {
                border-top: 1px solid rgba($black, 0.1);
                @include media-breakpoint-up(lg) {
                  margin-top: 6px;
                  border-top: 0;
                }
              }
              a.sub-link {
                display: block;
                padding: 11px 0;
                @include media-breakpoint-up(lg) {
                  padding: 0;
                }
              }
            }
          }
        }
      }
      // &:hover {
      //   @include media-breakpoint-up(xl) {
      //     a.menu-link {
      //       color: $white;
      //       background: $blue-2;
      //     }
      //     .child-menu {
      //       display: flex;
      //     }
      //   }
      // }
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.5);
  z-index: 500;
  display: none;
}

.menu-content.menu-content-expanded {
    @include media-breakpoint-down(sm) {
      display: none;
    }
}

.menu-parent-link + .menu-content {
  background: white;
  padding-left: 0 !important;
  & > ul > li {
    width: 100%;
    border-bottom: 1px solid lightgrey;
  }
  & > ul > li > a {
    color: $main !important;
    background: white !important;
    font-weight: bold;
    padding: 2px 16px;
    width: 100%;
    display: inline-block;
    background-color: white !important;
    vertical-align: top;
    &:hover {
      text-decoration: underline !important;;
    }
  }
  & > ul.menu-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: block;
    gap: 10px !important;
  }
}

.desktop-only {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.mobile-only {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}