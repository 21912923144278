.product-item {
  .product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    height: 170px;
    border: 1px solid #333;
    @include media-breakpoint-up(xl) {
      height: 255px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    &.product-img-search {
        max-height: 60px;
        // margin-bottom: 0;
        img {
            max-height: 58px;
        }
    }
  }
  .product-caption {
    .product-manufacturer {
      font-size: $font-size-12;
      opacity: 0.5;
      color: $black;
      min-height: 18px;
    }
    .product-code {
        @include textOverflow(1);
        margin: 0 !important;
        color: $black;
        font-size: $font-size-12;
    }
    .product-name {
      color: $black;
      font-size: $font-size-12;
      font-weight: 600;
      min-height: 36px;
      height: 36px;
      line-height: 18px;
      text-overflow: ellipsis;
      @include textOverflow(2);
      @include media-breakpoint-up(xl) {
        // min-height: 42px;
        // height: 42px;
        // line-height: 21px;
        min-height: 36px;
        height: 36px;
        line-height: 18px;
        font-size: $font-size-14;
        font-weight: 700;
      }
      @include media-breakpoint-down(sm) {
        min-height: 30px;
        // height: 30px;
      }

      &.product-search-name {
        @include textOverflow(1);
        height: 18px;
      }
    }

    .product-price {
      display: flex;
      align-items: flex-end;
      margin-bottom: 8px;
      .new {
        color: $primary;
        font-weight: 700;
        font-size: $font-size-16;
        margin-bottom: -1px;
        // @include media-breakpoint-up(xl) {
        //   font-size: $font-size-24;
        //   margin-bottom: -4px;
        // }
      }
      .old {
        font-size: $font-size-12;
        color: #727272;
        text-decoration: line-through;
        margin-left: 10px;
        @include media-breakpoint-up(xl) {
          font-size: $font-size-14;
          margin-bottom: -4px;
        }
      }
      .alv {
        font-size: $font-size-12;
        color: #727272;
        margin-left: 10px;
        @include media-breakpoint-up(xl) {
          font-size: $font-size-14;
        }
      }
    }
  }

  &.product-item-md {
    .product-img {
      height: 170px;
      border: 1px solid #333;
    }
    .product-caption {
      .product-manufacturer {
        font-size: $font-size-10;
      }
      .product-name {
        font-size: $font-size-12;
      }
      .product-price {
        .new {
          font-size: $font-size-14;
          margin-bottom: 0;
          @include media-breakpoint-up(xl){
            margin-bottom: -4px;
          }
        }
        .old {
          font-size: $font-size-12;
        }
        .alv {
          font-size: $font-size-12;
          color: #727272;
          margin-left: 10px;
          @include media-breakpoint-up(xl) {
            font-size: $font-size-14;
          }
        }
      }
      .product-rating{
        font-size: $font-size-14;
      }
    }
  }
}


.suusi-product-item-desc {
  // color: $black;
  // font-size: $font-size-12;
  // font-weight: 600;
  min-height: 36px;
  height: 36px;
  line-height: 18px;
  text-overflow: ellipsis;
  @include textOverflow(2);
  @include media-breakpoint-up(xl) {
    // min-height: 42px;
    // height: 42px;
    // line-height: 21px;
    min-height: 36px;
    height: 36px;
    line-height: 18px;
    font-size: $font-size-14;
    // font-weight: 700;
  }
}

.quick-add {
//   button[type='submit'], a {
//       padding: 0;
//       background-color: transparent;
//       border-color: transparent;
//   }
}
.suusi-product-item-cta-btn {
  @include media-breakpoint-down(sm) {
      min-width: 125px !important;
      a, button {
        font-size: $font-size-base * 0.75;
      }
  }
}

.suusi-product-item-figcaption {
  width: 100%;
  height: auto !important;
  overflow: hidden;
  border: 0 !important;
  padding-bottom: 100%;
  & > a {
    position: absolute;
    height: 100%;
  }
  .suusi-product-item-best-offer {
    z-index: 2;
  }
}
