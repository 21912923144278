body {
  &.ham-notify-page {
    @include media-breakpoint-up(md) {
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }
  }
}

.notify-container {
}

.notify-header {
  display: none;
  @include media-breakpoint-up(md) {
    text-align: center;
    margin-bottom: 50px;
    .logo {
      img {
        height: 60px;
      }
    }
  }
}

.notify-card {
  max-width: 540px;
  border-radius: 6px;
  border: none;
  margin: 0 auto;
  .card-body {
    height: 100vh;
    padding: 80px 16px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      display: block;
      height: auto;
      padding: 48px;
    }
    .card-icon {
      font-size: 56px;
      line-height: 1;
      margin-bottom: 20px;
      &.success {
        color: $success;
      }
    }
    .card-title {
      margin-bottom: 16px;
    }

    .card-action {
      flex: auto;
      flex-direction: column;
      justify-content: flex-end;
      @include media-breakpoint-up(md){
        flex-direction: row;
        margin-top: 40px;
        align-items: center;
      }
    }
  }
}
