.page-header {
  background: rgba($color: #d9d9d9, $alpha: 0.2);
  padding-bottom: 30px;
  margin-bottom: 30px;
  @include media-breakpoint-up(md) {
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
  .container {
    .headline-1 {
      color: #333333;
      font-size: 32px;
      font-weight: 700;
    }
  }
}

.container {
  .content {
    margin-bottom: 24px;
    .subtitle-3 {
      font-family: Roboto;
      font-style: normal;
      font-size: 0, 875rem;
      color: #333333;
      font-weight: 700;

      margin-bottom: 12px;
    }
    ul {
      font-family: Roboto;
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0, 01em;
      list-style-type: disc;
      padding-left: 30px;
      li {
        + li {
          margin-top: 10px;
        }
      }
    }
  }
}
