.suusi-product-item-container {
  --number-product-item: 4;
  display: flex;
  flex-wrap: wrap;
  margin-top: 64px;
  gap: 8px;

  @include media-breakpoint-down(md) {
    --number-product-item: 2;
  }

  @include media-breakpoint-down(sm) {
    --number-product-item: 1;
  }
}

.suusi-product-item {
  // width: calc(
  //   (100% - var(--number-product-item) * 8px) / var(--number-product-item)
  // );
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  &-less-info {
    .suusi-product-item-options,
    .suusi-product-item-caption {
      visibility: hidden;
    }
  }

  &-figcaption {
    position: relative;
    border: 1px solid;
    height: 300px;
    display: flex;
    justify-content: center;
    // flex-direction: column;

    @include media-breakpoint-down(md) {
      height: 250px;
    }
  }

  &-best-offer {
    background: $white;
    position: absolute;
    padding: 0 4px;
    top: 4px;
    left: 4px;
    font-size: $font-size-10;
  }

  &-best-offer-overlay {
    position: absolute;
    left: 0;
    top: 5px;
    width: 100%;
    height: 10px;
    opacity: 0.5;
    background-color: $primary;
  }

  &-img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  &-caption {
    font-size: $font-size-10;
    position: absolute;
    right: 4px;
    bottom: 0px;
  }

  &-info {
    text-align: center;
  }

  &-title {
    opacity: 0.5;
    color: $black;
    margin-top: 16px;
  }

  &-desc {
    font-size: $font-size-12;
    margin-top: 4px;
  }

  &-options {
    margin-top: 4px;
    &-1,
    &-2 {
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 50%;
    }

    &-1 {
      background: $primary;
    }

    &-2 {
      background: #334c1f;
    }
  }

  &-cta-btn {
    margin-top: 16px;
    min-width: 150px;
  }
}

.suusi-product-indicators {
  margin-top: 64px;
  .carousel-indicators {
    bottom: -50px;
  }

  .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $gray;
  }
}
