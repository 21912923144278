.personal-tabs {
  border-bottom: 1px solid #e6e6e6;
  @include media-breakpoint-down(md) {
    display: block;
    overflow: auto;
    white-space: nowrap;
    @include disabledScrollbar();
  }
  .nav-link {
    margin: 0 0 -1px;
    padding: 0 0 10px;
    border: none;
    color: $main;
    font-weight: 600;
    border-bottom: 3px solid transparent;
    font-size: $font-size-14;
    @include media-breakpoint-up(md) {
      font-size: $font-size-18;
    }
    @include media-breakpoint-down(md){
      display: inline-block;
      margin-bottom: 0;
    }
    + .nav-link {
      margin-left: 24px;
      @include media-breakpoint-up(md) {
        margin-left: 40px;
      }
    }
    &.active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}
