.alert {
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-border-radius: 0px;
  font-size: $font-size-14;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-16;
  }
  b {
    font-weight: 600;
  }
}

.alert-primary {
  --bs-alert-color: #333;
  --bs-alert-bg: #fcf0ea;
  --bs-alert-border-color: #fcf0ea;
  > i {
    color: $primary;
    font-size: $font-size-20;
    margin-right: 10px;
  }
}

.alert-personal {
  display: block;
  margin: 0px -16px;
  padding-left: 46px;
  position: relative;
  > i {
    position: absolute;
    left: 16px;
    top: 10px;
  }
  .alert-action {
    text-align: right;
    padding-top: 10px;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-left: 10px;
    }
  }
  @include media-breakpoint-up(lg) {
    position: static;
    display: flex;
    margin: 0;
    padding-left: 0.75rem;
    > i {
      position: static;
    }
  }
}

.floating-alert {
  position: fixed;
  bottom: 0;
  right: 0px;
  left: 0;
  z-index: 2000;
  padding: 0 16px;
  @include media-breakpoint-up(md) {
    top: 0px;
    bottom: auto;
    left: auto;
    padding: 24px;
  }
}

.ham-alert {
  display: flex;
  border: none;
  background: $white;
  box-shadow: 0px 4px 8px rgba(221, 221, 221, 1);
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: all 0.3s linear;
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  @include media-breakpoint-up(md) {
    min-width: 420px;
  }
  .alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    flex-shrink: 0;
    i {
      font-size: $font-size-20;
    }
  }
  .alert-content {
    flex: auto;
    background: $white;
    padding: 16px;
    color: $main;
    font-size: $font-size-12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .alert-title {
      font-size: $font-size-14;
      line-height: 1.5;
      font-weight: 500;
    }
    .alert-message {
      line-height: 1.2;
      margin-top: 4px;
    }
  }
  .btn-closealert {
    color: $main;
    font-size: $font-size-20;
    padding: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    // @include media-breakpoint-up(md) {
    //   align-items: flex-start;
    // }
    cursor: pointer;
    &:hover {
      color: $error;
    }
  }

  &.alert-danger {
    .alert-icon {
      color: #f44336;
      background: #ffdce4;
    }
  }
  &.alert-warning {
    .alert-icon {
      color: #ffc400;
      background: #fff3cc;
    }
  }
  &.alert-success {
    .alert-icon {
      color: #00b140;
      background: #ddf7e0;
    }
  }
}
