@mixin headline($size: $font-size-16) {
  font-size: $size;
  font-weight: 600;
  font-style: normal;
}

@mixin subtitle($size: $font-size-16) {
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: $size;
}

@mixin screen-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(xl) {
    display: block;
    padding-top: 120px;
  }
}

@mixin disabledScrollbar {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }
}

@mixin textOverflow($line) {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
