.product-category {
  margin-bottom: 24px;
  .category-swiper {
    overflow: visible;
    .swiper-wrapper {
      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
        .swiper-slide {
          padding: 0;
        }
      }
    }
  }
}

.product-category-info {
  margin-bottom: 40px;
  .category-name {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: $font-size-24;
    @include media-breakpoint-up(xl) {
      font-size: $font-size-32;
    }
  }
  .category-description {
    font-size: $font-size-12;
    max-width: 730px;
    @include media-breakpoint-up(xl) {
      font-size: $font-size-14;
    }
  }
}

.product-rating {
  white-space: nowrap;
  font-size: $font-size-14;
  @include media-breakpoint-up(xl) {
    font-size: $font-size-16;
  }
  .fa-solid {
    color: $primary;
  }
  .fa-regular {
    color: #5f6368;
  }
}
