.table {
  font-size: $font-size-12;
  thead {
    tr {
    }
    th {
      font-weight: 400;
      vertical-align: middle;
      background: #e6e6e6;
      border-bottom: none;
    }
  }
  tbody {
    background: rgba(#e6e6e6, 0.2);
    tr {
      td {
        border-bottom-color: #e6e6e6;
        color: #4f4f4f;
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .btn-link {
    font-size: $font-size-12;
    font-weight: 400;
  }
}

.table-hover {
  > tbody {
    > tr:hover {
      > * {
        --bs-table-accent-bg: rgba(#e6e6e6, 0.2);
        @include media-breakpoint-up(md) {
          --bs-table-accent-bg: #fcf0ea;
        }
      }
    }
  }
}
