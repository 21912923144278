%textAlignCenterAndLineClamp {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: var(--suusi-banner-line-clamp);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin suusiBannerPosition($position) {
  @if $position == center {
    left: 50%;
    top: 40%;
    translate: -50% 0%;
    @include media-breakpoint-down(sm) {
      top: 20%;
    }
  }
  @if ($position == left) {
    left: 35%;
    top: 40%;
    translate: -50% 0%;
  }

  @if ($position == right) {
    right: 35%;
    top: 40%;
    left: unset;
    translate: 50% 0%;
  }
}

.banner-container {
  width: 100%; 
  min-height: 60vh; 
  position: relative;
}

.suusi-banner-container {
  height: calc(100vh - 160px);
  position: relative;
  --suusi-banner-line-clamp: 2;

  .img-temp {
    width: 50%;
  }
}

.suusi-banner {
  position: absolute;
  top: 50%;
  @include suusiBannerPosition(center);

  &-center {
    @include suusiBannerPosition(center);
  }

  &-left {
    @include suusiBannerPosition(left);

    @include media-breakpoint-down(md) {
      @include suusiBannerPosition(center);
    }
  }

  &-right {
    @include suusiBannerPosition(right);

    @include media-breakpoint-down(md) {
      right: unset;
      @include suusiBannerPosition(center);
    }
  }

  &-info {
    width: 350px;
    padding: 32px;
    // background: $primary;
    @include media-breakpoint-down(sm) {
      max-width: 300px;
    }
  }

  &-title {
    @extend %textAlignCenterAndLineClamp;
    padding: 0 40px;
  }

  &-desc {
    @extend %textAlignCenterAndLineClamp;
    --suusi-banner-line-clamp: 3;
  }

  .btn-suusi-banner-cta {
    position: absolute;
    left: 50%;
    translate: -50%;
    bottom: -100px;
  }
}

.suusi-banner-background {
  height: calc(100vh - 158px);
  display: flex;
  &-left,
  &-right {
    width: 50%;
    height: 100%;
  }

  &-left {
    background-color: #c9c9c9;
  }

  &-right {
    background-color: #b6b6b6;
  }
}
