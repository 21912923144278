.suusi-carousel {
  &-title {
    margin: 16px 0 64px;
    text-align: center;
  }

  &-item {
    width: 300px;
    height: 300px;
  }

  &-img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }

  .suusi-carousel-chevron {
    position: absolute;
    top: 50%;
    padding: 16px;
    cursor: pointer;
    font-size: $font-size-24;
    z-index: 1;

    &-left {
      left: 30px;
    }

    &-right {
      right: 30px;
    }
  }
}
