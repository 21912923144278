.payment-section {
  background: #ffffff;
  margin: 0 -16px 12px;
  padding: 26px 16px;
  border-bottom: 10px solid #f5f5f5;
  @include media-breakpoint-up(md) {
    border-bottom: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin: 0px 0px 12px;
    padding: 26px 30px;
  }
}

.payment-page {
}
.address-selection {
  .form-check {
    margin-bottom: 12px;
  }
  .default-address {
    background: #fafafa;
    padding: 20px 24px;
    margin-bottom: 24px;

    .address-edit {
      border-top: 1px solid #e6e6e6;
      margin-top: 12px;
      padding-top: 12px;
    }
    .btn-edit {
      padding: 4px 16px;
      border-color: #bdbdbd;
      > i {
        font-size: $font-size-14;
        margin-right: 9px;
        margin-top: -2px;
      }
      &:hover {
        background: #bdbdbd;
      }
    }
  }

  .another-address {
    padding-left: 1.5rem;
  }
}

.address-value {
  .row-value {
    margin-bottom: 12px;
    font-size: $font-size-14;
    label {
      width: 100px;
      opacity: 0.6;
      text-align: right;
      margin-bottom: 0;
    }
    .value {
      width: calc(100% - 100px);
      padding-left: 28px;
    }
  }
}

.shipping-method {
  @extend .payment-section;
}

.shipping-selection {
}

.payment-method {
  @extend .payment-section;
}

.order-review {
  background: $white;
  margin: 0px -16px 12px;
  padding: 26px 16px;
  @include media-breakpoint-up(md) {
    margin: 0px 0px 12px;
    padding: 26px 30px;
  }
  .total-order {
    font-size: $font-size-12;
    color: #4f4f4f;
  }

  .order-list {
    .order-item {
      display: flex;
      padding: 16px 0;
      + .order-item {
        border-top: 1px solid #e0e0e0;
      }

      .item-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        img {
          max-width: 90%;
          max-height: 90%;
        }
      }
      .item-caption {
        padding-left: 14px;
        .item-name {
          color: $black;
          font-size: $font-size-12;
          font-weight: 600;
          margin-bottom: 8px;
          padding-right: 20px;
        }
        .input-amount {
          width: 80px;
        }
        .item-price {
          font-weight: 600;
        }
      }
    }
  }
}
