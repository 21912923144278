.user-navigation {
  display: block;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  box-shadow: 0px -4px 16px rgba(226, 226, 236, 0.5);
  white-space: nowrap;
  overflow: auto;
  @include media-breakpoint-up(lg) {
    position: static;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  .navigation-link {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
    padding: 10px 10px;
    @include media-breakpoint-up(lg) {
      display: flex;
      width: 100%;
      flex: unset;
      flex-direction: row;
      padding: 12px 24px;
      border-left: 3px solid transparent;
      + .navigation-link {
        border-top: 1px solid #e6e6e6;
      }
    }
    .icon {
      text-align: center;
      width: 32px;
      font-size: $font-size-18;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-24;
      }
      i {
      }
    }
    .text {
      width: 100%;
      font-size: $font-size-10;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      @include media-breakpoint-up(lg) {
        width: auto;
        font-size: $font-size-16;
        padding-left: 14px;
      }
    }
    &.active {
      color: $primary;
      @include media-breakpoint-up(lg) {
        border-left-color: $primary;
      }
    }
  }
}
