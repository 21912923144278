@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  overflow-x: hidden;
  min-height: 100vh;
  color: $main;
  background: $white;
  margin: 0;
  padding: 0;
  &.ham-product{
    background: #f9f9f9;
  }
  &.ham-cart, &.ham-personal {
    @include media-breakpoint-up(md) {
      background: #f9f9f9;
    }
  }

  * {
    letter-spacing: 0.01em;
  }
}

.fullpage {
  width: 100%;
  // height: 100%;
  // overflow: hidden;
}

// INIT BASE SCSS

img,
svg {
  vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

iframe {
  vertical-align: top;
}

.container {
  &.container-md {
    max-width: 730px;
    margin: 0 auto;
  }
}
