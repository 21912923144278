.home-banner {
  margin: 0px -12px;
  @include media-breakpoint-up(md){
    margin: 0;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 20px;
  }
  .banner-img {
    img {
      width: 100%;
    }
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 2px;
      transition: all 0.3s ease;
      &.swiper-pagination-bullet-active {
        background: $primary;
        width: 35px;
        border-radius: 100px;
      }
    }
  }
}
